.input-group {
    display: flex;
    margin-bottom: 25px;

    .form-control {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.input-group-addon {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border: 1px solid #ced4da;
    border-right: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #e9ecef;
}
