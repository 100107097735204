.community-post {
    margin-bottom: 2rem;
    padding: 2rem;
    background-color: $background-color;

    > *:last-child {
        margin: 0;
    }

    .redactor-in {
        position: relative;
        margin-bottom: 1rem;
        padding: 20px 20px 24px 20px;
        border: 1px solid rgba(#000, 0.07);
        overflow: auto;
        background-color: #fff;
        white-space: normal;
    }
}

.community-post-comment-summary {
    font-size: 14px;

    .community-post-meta {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 500;
        color: $text-color;
    }
}

.community-post-topic-summary {
    .community-post-heading {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .community-post-header {
        flex-wrap: nowrap;
    }
}

.community-post-heading {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 700;
    font-family: $font-family-sans-serif;
    font-size: 20px;
    color: $text-color;

    &:hover {
        color: $brand-primary;
    }
}

.community-post-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    font-size: 14px;
    color: #9f9f9f;

    svg {
        vertical-align: middle;
        width: 20px;
        height: 20px;
    }

    .modal {
        color: $text-color;
    }
}

.community-post-header-larger {
    margin-bottom: 1rem;
}

.community-post-meta {
    .initials-holder {
        margin-right: 10px;
    }
}

.community-post-comments {
    white-space: nowrap;
}

.community-post-meta-flex {
    display: flex;
    align-items: center;
    width: 100%;
}

.community-post-actions {
    @include addActionStyles;
    flex: 1 0;
    text-align: right;
}

.community-post-help {
    margin-top: 10px;
    font-size: 12px;
}

.community-file-size-help {
    font-size: 12px;
    color: #9f9f9f;
}

.community-post-empty {
    text-align: center;
}

.js-comment-message {
    img {
        max-width: 100%;
    }
}
