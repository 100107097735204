.icon {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

.icon-success {
    &,
    path {
        fill: $success-color;
    }
}

.icon-failed {
    &,
    path {
        fill: #ab0000;
    }
}
