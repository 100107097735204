.btn {
    display: inline-flex;
    align-items: center;
    margin-right: 8px;
    margin-bottom: 5px;
    padding: 11px 30px;
    border: none;
    border-radius: 30px;
    background-color: $brand-primary;
    color: #fff;
    fill: #fff;
    cursor: pointer;

    &:hover,
    &:focus {
        background-color: mix(#fff, $brand-primary, 15%);
        color: #fff;
    }

    &:focus,
    &:active:focus {
        outline: none;
    }

    &.is-loading {
        position: relative;
        width: 52px;
        height: 52px;
        padding: 0;
        color: transparent !important; // Overwrite inline styles & modifiers
        pointer-events: none;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            width: 24px;
            height: 24px;
            margin-top: -12px;
            margin-left: -12px;
            border: 3px solid transparent;
            border-radius: 50%;
        }

        &:before {
            border-color: rgba(#fff, 0.2);
        }

        &:after {
            border-top-color: #fff;
            animation: spin 1.5s linear infinite;

            @keyframes spin {
                to {
                    transform: rotateZ(360deg);
                }
            }
        }
    }

    .icon {
        position: relative;
        top: -2px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 4px;
        margin-left: -8px;
    }

    svg {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 8px;
    }
}

.btn-secondary { // Modifier
    background-color: $brand-secondary;
    color: $ro-groen;
    fill: $ro-groen;

    &:hover,
    &:focus {
        background-color: mix(#000, $brand-secondary, 5%);
        color: $ro-groen;
        fill: $ro-groen;
    }

    &:focus,
    &:active:focus {
        outline: none;
    }

    &.is-loading {
        &:before {
            border-color: rgba($text-color, 0.15);
        }

        &:after {
            border-top-color: rgba($text-color, 0.3);
        }
    }
}

.btn-white { // Modifier
    background-color: #fff;
    color: $brand-primary;

    &:hover,
    &:focus {
        background-color: mix(#fff, $brand-primary, 15%);
        color: #fff;
    }
}

.btn-small { // Modifier
    padding: 4px 16px;
    font-size: 14px;
}

.btn-outline { // Modifier
    border: 2px solid $brand-primary;
    color: $brand-primary;
    fill: $brand-primary;
    background: none;

    &:hover,
    &:focus {
        background-color: $brand-primary;
        color: #fff;
        fill: #fff;
    }
}

.btn-link { // Modifier
    padding: 0;
    margin: 0;
    font-weight: 300;

    &,
    &:hover,
    &:focus {
        border: none;
        background: none;
        color: $brand-primary;
        fill: $brand-primary;
    }

    &:hover,
    &:focus {
        text-decoration: underline;
    }

    &.btn-secondary { // Modifier
        color: $text-color;

        &:hover,
        &:focus {
            color: $brand-primary;
            text-decoration: none;
        }
    }

    svg {
        margin-right: 6px;
        margin-top: -4px;
    }
}

.btn-add { // Modifier
    svg {
        vertical-align: sub;
        width: 20px;
        height: 20px;
        margin: 0 0 0 10px;
    }
}

.btn-icon { // Modifier
    padding: 0;
    border: none;
    background: none;
    fill: $brand-primary;

    &:hover,
    &:focus,
    &:active {
        fill: $text-color;
        background: none;
    }

    svg {
        width: 16px;
        height: 16px;
    }
}
