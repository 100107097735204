.popup {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;

    &.is-active {
        display: flex;
        z-index: 999;
    }

    .form > .form-group {
        margin: 0;
    }
}

.popup-center { // Modifier
    align-items: center;
    justify-content: center;
    padding: 3vh 3vw;

    .popup-content {
        max-height: 100%;
    }
}

.popup-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    opacity: 0.8;
    background-color: $backdrop-color;
    cursor: default;
}

.popup-content {
    position: relative;
    top: 5vh;
    width: 90%;
    max-width: 800px;
    height: auto;
    max-height: calc(90% - 40px);
    overflow-x: hidden;
    overflow-y: auto;
    background: #fff;
    border-radius: 10px;
}

.popup-content-spaceless { // Modifier
    padding: 0;
}

.popup-content-small { // Modifier
    max-width: 560px;
    padding: 40px 60px;
}

.popup-content-primary { // Modifier
    background-color: $brand-primary;
    color: #fff;
    fill: #fff;
}

.popup-close {
    float: right;
    margin: 30px 30px 0 0;
    padding: 0;
    border: none;
    background: none;

    &:hover,
    &:focus {
        fill: $brand-primary;
    }
}

.popup-close-icon {
    width: 18px;
    height: 18px;
}

.popup-title {
    padding: 20px 0;
    text-align: center;
    font-size: $font-size-largest;
    font-weight: 500;
}

.popup-heading {
    margin: 0 0 15px;
    text-align: left;
    font-weight: 300;

    em {
        font-style: normal;
        font-weight: 500;
        color: #fff;
    }
}

.popup-icon {
    display: block;
    width: 48px;
    height: 48px;
    margin: 20px auto 35px;
}

.popup-action {
    display: inline-block;
    margin: 20px auto 10px;
}
