.btn {
    padding: 9px 30px;

    &[disabled] {
        opacity: 0.5;
        pointer-events: none;
    }
}

.btn-outline {
    &:hover {
        svg path {
            fill: #fff;
        }
    }
}

.btn--loading {
    position: relative;
    padding-right: 50px;
    pointer-events: none;

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 33px;
        right: 20px;
        display: block;
        width: 20px;
        height: 20px;
        margin-top: -20px;
        margin-left: -20px;
        border: 3px solid transparent;
        border-radius: 50%;
    }

    &:before {
        border-color: $border-color;
    }

    &:after {
        border-top-color: $brand-primary;
        animation: spin 1.5s linear infinite;
    }

    &:hover,
    &:focus {
        &:before {
            border-color: rgba(#fff, 0.2);
        }

        &:after {
            border-top-color: #fff;
        }
    }
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}
