$ui-whitespace-modal: $ui-whitespace * 1.5;

.modal {
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: auto;
    text-align: left;
    cursor: auto;
}

.modal-state {
    display: none;

    &:not(:checked) + .modal {
        display: none;
    }
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    background-color: mix(#000, $background-color, 25%);
    opacity: 0.5;
    cursor: default;
}

.modal-content-container {
    position: relative;
    max-width: 100%;
    padding: 10vh 40px;
    pointer-events: none;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 640px;
    max-width: 100%;
    padding: $ui-whitespace-modal;
    background-color: $ui-background-color;
    pointer-events: all;

    &.is-wide {
        width: 1240px;
    }

    &.has-sidebar {
        flex-direction: row;
        align-items: stretch;
    }
}

.modal-main {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: stretch;
    margin: (-$ui-whitespace-modal) (-$ui-whitespace-modal) (-$ui-whitespace-modal) 0;
    padding: $ui-whitespace-modal;
}

.modal-main-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: auto;
    padding-top: $ui-whitespace-modal;

    .btn-link {
        margin-right: 25px;
    }
}

.modal-main-footer-primary {
    margin-left: auto;
}

.modal-section {
    display: flex;
    flex-direction: column;
    width: 100%;

    &:last-child {
        flex: 1 0 auto;
    }

    > * {
        max-width: 100%;
    }
}

.modal-section-center { // Modifier
    flex: 1 0 auto;
    justify-content: center;
    align-items: center;
    max-width: 540px;
    margin: 0 auto;
    padding: 30px 0;
    text-align: center;

    .btn:last-child {
        margin-top: 10px;
    }

    .empty {
        padding-top: 5px;
        padding-bottom: 15px;

        &:first-child {
            padding-top: 20px;
        }

        &:last-child {
            padding-bottom: 20px;
        }
    }
}

.modal-body {
    line-height: $line-height-base;
    font-size: $font-size-base;

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.modal-seperator {
    display: block;
    width: auto;
    margin: 10px 0 20px;
    border: none;
    background-color: $border-color;
}

.modal-title {
    display: block;
    margin-bottom: 25px;
    padding-right: 40px;
    font-size: 24px;
}

.modal-title-large { // Modifier
    margin: 0.5em 0 1.5em;
    font-size: 30px;
    text-align: center;
}

.modal-section-title {
    margin-bottom: 6px;
    margin-top: 1em;
    font-size: 24px;
    font-weight: normal;

    &:first-child {
        margin-top: 0;
    }
}

.modal-sidebar {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    width: 360px;
    margin: (-$ui-whitespace-modal) 0 (-$ui-whitespace-modal) (-$ui-whitespace-modal);
    padding: $ui-whitespace-modal;
    background-color: $background-color-lighter;
}

.modal-sidebar-footer {
    margin-top: auto;
    padding-top: $ui-whitespace-modal;
}

.modal-sidebar-title {
    font-size: $font-size-base;
    font-weight: 400;
}

.modal-sidebar-seperator {
    display: block;
    width: auto;
    margin: 20px (-$ui-whitespace-modal) 30px;
    border: none;
    background-color: $border-color;
}

.modal-close {
    position: absolute;
    z-index: 2;
    top: -5px;
    right: -5px;
    display: block;
    padding: $ui-whitespace-modal $ui-whitespace-modal 0 0;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
}

.modal-close-icon {
    display: block;
    width: 32px;
    height: 32px;
    fill: $text-color;

    .modal-close:hover &,
    .modal-close:focus & {
        fill: $ro-groen;
    }
}

.modal-cta {
    display: block;
    margin: 45px auto 0;
}
