.session-item {
    font-size: 14px;

    &.is-open {
        .session-item-content {
            display: block;
        }

        .icon {
            transform: rotate(180deg);
        }
    }
}

.session-item-no-click {
    .session-item-heading {
        cursor: initial;
    }

    .icon {
        visibility: hidden;
    }

    .session-item-content {
        display: none;
        margin: 0;
    }
}

.session-item-heading {
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid #d1d1d1;
    cursor: pointer;
}

.session-item-type {
    flex: 1 0 20%;
}

.session-item-event {
    flex: 1 0 65%;
}

.session-item-date {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1 0 15%;

    .icon {
        width: 25px;
        height: 25px;
        fill: $brand-primary;
    }
}

.session-list-table {
    margin: 0;

    > tbody > tr th {
        border-top: 0;
    }
}

.session-item-content {
    display: none;
    margin: 10px 0;
    padding: 0 20px;
}
