$catalog-whitespace: 40px !default;

.catalog {
    margin-bottom: 150px;
}

.catalog-item {
    display: block;
    border-bottom: 2px solid $background-color;
}

.catalog-item-intro {
    border-left: 10px solid transparent;

    .main-content {
        padding-top: $catalog-whitespace * 1.25;
        padding-bottom: $catalog-whitespace;
    }
}

.catalog-item-title {
    display: block;
    margin-bottom: 0.25em;
    font-weight: 500;
    font-size: 32px;
}

.catalog-item-description {
    @include mq($from: tablet) {
        columns: 2;
        column-gap: $catalog-whitespace;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.catalog-item-content {
    position: relative;
    border-left: 10px solid;
    background-color: $background-color;

    .main-content {
        padding-top: $catalog-whitespace * 2;
        padding-bottom: $catalog-whitespace * 1.5;

        @include mq($from: desktop) {
            padding-left: 65px;
            padding-right: 65px;
        }
    }
}

.catalog-item-content-label {
    z-index: 3;
    top: 0px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    text-align: center;
    color: #fff;
    font-size: 18px;

    @include mq($from: desktop) {
        position: sticky;
    }
}

.catalog-item-state {
    display: none;
}

// Groups
.catalog-group {
    display: block;
    padding: 0 0 $catalog-whitespace;
    margin: 0 0 $catalog-whitespace;
    border-bottom: 2px solid mix(#fff, $border-color, 25%);

    &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
    }
}

.catalog-group-state {
    display: none;
}

.catalog-group-intro {
    display: flex;
    align-items: flex-start;
}

.catalog-group-intro-details {
    flex: 1 1 auto;
    margin: -10px 50px 10px 0;
}

.catalog-group-intro-usps {
    flex: 0 0 auto;
    margin-top: -1px;
}

.catalog-group-content {
    padding-top: 15px;

    .table:last-child {
        margin-bottom: -10px;
    }
}

// Search
.catalog-search-container {
    display: block;
    padding: 25px 0;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;

    .main-content {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.catalog-search {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 16px;

    @include mq($from: desktop) {
        flex-direction: row;
    }

    .select {
        margin: 0;
    }

    .active {
        color: $brand-primary;
    }

    .catalog-search-wrapper {
        margin: 0;
        gap: 4px;

        @include mq($from: desktop) {
            max-width: 320px;
        }
    }

    .search-btn {
        border-radius: $ui-border-radius;
        padding: 10px 13px;
    }
}

.catalog-search-input-container {
    flex: 1;

    .select2-container, .select2-container .select2-selection {
        height: 100%;
    }
}

.catalog-search-input-option {
    &.level {
        font-weight: 700;
    }

    &.measure {
        padding-left: 1rem;
    }

    &.variant {
        padding-left: 2rem;
    }
}

.catalog-search-wrapper {
    display: flex;
    align-items: stretch;
    gap: 1rem;
    width: 100%;
}

.search-btn {
    margin: 0;
    gap: 4px;
    line-height: 1;

    svg {
        margin: 0;
    }
}

.catalog-search-sort {
    display: flex;
    align-items: center;
    gap: 10px;

    @include mq($from: desktop) {
        margin-left: auto;
    }

    .select {
        flex-grow: 1;
    }

    label {
        margin: 0;
    }
}

// Details
.catalog-detail-content-section {
    padding: 1em 0;
}

.catalog-detail-table {
    .blocks & {
        margin: 0;
    }

    th {
        padding-top: 0 !important;
        border-top: 0 !important;
        font-weight: 700 !important;
    }
}

@include mq($from: $content-breakpoint) {
    .catalog-item-content {
        .main-content {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .catalog-item-content-label {
        width: 64px;
        height: 64px;
        font-size: 20px;
    }
}

.catalog-search-warning {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 10px;
    background-color: mix(#fff, #FFAD13, 50%);
    line-height: 1.4;

    p {
        margin: 0;
    }
}
