/* stylelint-disable */

.redactor-modal-tab {
    .checkbox input[type='checkbox'] {
        position: static;
        display: inline-block;
        margin: 0;
    }
}

#redactor-modal button#redactor-modal-button-action {
    background-color: $brand-primary;

    &:hover,
    &:focus {
        background-color: mix(#fff, $brand-primary, 15%);
        color: #fff;
    }
}

.redactor-container {
    .js-upload-error {
        color: $error-color;
    }
}
