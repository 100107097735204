.section {
    padding: 5rem 0;

    & + & {
        padding-top: 0;
    }
}

.section-small {
    padding: 2.5rem 0;

    & + & {
        padding-top: 0;
    }
}

.section-heading {
    margin-bottom: 2rem;
    font-size: 1.5rem;
}

.section-teaser {
    padding-top: 5rem;
    background-color: #fff;
}

.section-split {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 1rem;
}

.section-split-item-full {
    width: 100%;
}

@include mq($from: desktop) {
    .section-split {
        flex-direction: row;
    }

    .section-split-item {
        width: 50%;
        margin-bottom: 0;
        padding: 0 1rem;
    }

    .section-teaser {
        position: relative;
        margin-top: -8rem;
        padding-top: 4rem;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 0;
            width: 4rem;
            height: 100%;
            background-color: #fff;
        }

        &:before {
            right: 100%;
        }

        &:after {
            left: 100%;
        }
    }
}
