@mixin addActionStyles {
    .btn {
        margin: 0;
    }

    .modal-actions .btn {
        margin-right: 20px;
    }

    svg {
        width: 19px;
        height: 19px;
    }

    .delete {
        width: 24px;
        height: 24px;
    }

    > button {
        padding: 0;
        border: 0;
        background: transparent;
        fill: $brand-primary;

        &[disabled] {
            opacity: 0.5;
            pointer-events: none;
        }
    }
}
