@each $size, $value in $spacing {
    .u-margin-top-#{$size} {
        margin-top: #{$value};
    }

    .u-margin-bottom-#{$size} {
        margin-bottom: #{$value};
    }
}

.u-flatten {
    margin-bottom: 0;
}

.u-align-right {
    text-align: right !important;
}
