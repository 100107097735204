.toggleable-state {
    position: absolute;
    top: -9999em;
    width: 1px;
    height: 1px;
    visibility: hidden;

    &:checked ~ .toggleable-notification,
    &:checked ~ .toggleable-content {
        display: block;
    }

    &:checked ~ table.toggleable-notification,
    &:checked ~ table.toggleable-content {
        display: table;
    }
}

.toggleable {
    display: inline-block;
}

.toggleable-label {
    position: relative;
    top: -1px;
    vertical-align: middle;
    width: 18px;
    height: 18px;
    border: 2px solid $brand-primary;
    border-radius: 2px;
    cursor: pointer;

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        width: 100%;
        height: 100%;
    }

    &:before {
        background-color: $brand-primary;
    }

    &:after {
        transform: scale(1.3);
        // TODO: replace this with SVGSpritemapWebpackPlugin 2.0.0 styles option
        //background-image: url('../images/sprites/check.svg');
        background-size: contain;
    }

    .toggleable-state[type='radio'] + & {
        &,
        &:before,
        &:after {
            border-radius: 50%;
        }
    }

    .toggleable-state:checked + & {
        &:before,
        &:after {
            display: block;
        }
    }

    &.is-loading {
        border-color: transparent;
        background: none;

        &:before,
        &:after {
            top: -15%;
            left: -15%;
            display: block;
            width: 130%;
            height: 130%;
            border: 2px solid;
            border-radius: 50%;
            transform: none;
            background: none;
        }

        &:before {
            border-color: rgba($brand-primary, 0.25);
        }

        &:after {
            border-color: transparent;
            border-top-color: $brand-primary;
            animation: spin 1.5s linear infinite;

            @keyframes spin {
                to {
                    transform: rotateZ(360deg);
                }
            }
        }
    }
}

.toggleable-notification,
.toggleable-content {
    display: none;
}

.toggleable-notification-icon {
    position: relative;
    top: -2px;
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    margin-right: 5px;
}
