.initials-holder {
    display: flex;
    align-items: center;
}

.initials {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: $ro-lichtblauw;
    color: $ro-tekstlinks;
    font-weight: 700;
}

.initials-large {
    width: 50px;
    height: 50px;
}

.initials-small {
    font-size: 12px;
}
