.pagination {
    ul {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin: 5vh 0 0;
        padding: 0;
        text-align: center;
    }

    li {
        display: inline-block;
        margin: 0 3px;

        &.active {
            a,
            span {
                background-color: $brand-primary;
                color: #fff;
            }
        }

        &.disabled {
            a,
            span {
                border-color: #999;
                background: none;
                cursor: not-allowed;
            }

            span:after {
                color: #999;
            }
        }

        &:first-child,
        &:last-child {
            a,
            span {
                font-size: 0;
                color: transparent;

                &:after {
                    font-size: 18px;
                }
            }

            a:hover,
            a:focus {
                &:after {
                    color: #fff;
                }
            }
        }

        &:first-child {
            &.disabled span:after {
                content: '\00ab'; //&laquo;
            }

            a:after {
                content: '\00ab'; //&laquo;
                color: $brand-primary;
            }
        }

        &:last-child {
            &.disabled span:after {
                content: '\00bb'; //&raquo;
            }

            a:after {
                content: '\00bb'; //&raquo;
                color: $brand-primary;
            }
        }
    }

    a,
    span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45px;
        height: 45px;
        border: 1px solid $brand-primary;
        border-radius: 4px;
        color: $brand-primary;

        &:hover,
        &:focus {
            border-color: mix(#fff, $brand-primary, 25%);
            background-color: mix(#fff, $brand-primary, 25%);
            color: #fff;
            text-decoration: none;
            cursor: pointer;
        }
    }
}

.pagination__info {
    margin-top: 1rem;
    text-align: center;
    font-size: 14px;
}
