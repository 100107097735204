.building-queue {
    .loader {
        position: relative;
        display: inline-block;
        height: 12px;
        margin-right: 15px;
        padding: 0;
        color: transparent !important; // Overwrite inline styles & modifiers
        pointer-events: none;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            width: 24px;
            height: 24px;
            margin-top: -12px;
            margin-left: -12px;
            border: 3px solid transparent;
            border-radius: 50%;
        }

        &:before {
            border-color: rgba(#000, 0.2);
        }

        &:after {
            border-top-color: $brand-primary;

            @keyframes spin {
                to {
                    transform: rotateZ(360deg);
                }
            }
        }

        &.is-processing {
            &:after {
                animation: spin 1.5s linear infinite;
            }
        }

        &.is-loaded {
            &:before,
            &:after {
                content: none;
            }
        }
    }
}
