.article-description {
    font-size: 18px;
}

.article-time {
    display: block;
    margin-bottom: 1rem;
    color: #999;
    font-size: 14px;
}

.article-heading {
    margin: 0;
}
