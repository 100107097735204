$usp-states: (
    positive: $success-color,
    neutral: $warning-color,
    negative: $error-color
);

.usps {
    display: flex;
    flex-wrap: wrap;
    margin: -5px -18px;
    padding: 0;
}

.usps-list {
    flex-direction: column;
    align-items: flex-start;
    margin: 18px -18px;
}

.usp {
    position: relative;
    top: -2px;
    display: inline-block;
    vertical-align: middle;
    padding: 0 0 0 30px;
    margin: 5px 18px;
    line-height: 1.5;

    &[class*='is-']:before {
        content: '';
        position: absolute;
        top: 2px;
        left: 0;
        width: 18px;
        height: 18px;
    }

    @each $name, $color in $usp-states {
        &.is-#{$name}:before {
            background-color: $color;
        }
    }
}

.usp-help {
    display: inline-block;
    cursor: help;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
    }

    &,
    &:hover,
    &:focus {
        font-size: $font-size-base;
        color: $text-color;
        text-decoration: none;
    }
}

.usp-value { // Modifier
    width: 18px;
    height: 18px;
    margin: 0;
    padding: 0;
}
