.hamburger {
    display: inline-flex;
    align-items: center;
}

.hamburger-text {
    display: inline-block;
    margin-right: 10px;
    font-size: 14px;
    line-height: 1;
    color: $ro-donkergroen;
}
