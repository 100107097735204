@use "sass:math";

$project-excerpts-gutter-width: 40px !default;
$project-excerpts-aspect-ratio: math.div(16, 9);

.project-excerpt-details {
    display: block;
}

.project-excerpt-title {
    display: block;
    margin: 0;
    font-size: 20px;
    padding: 0 10px 10px;

    a {
        color: $text-color;
    }
}

.project-excerpts {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: $project-excerpts-gutter-width;

    @include mq($from: mobile) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include mq($from: tablet) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @include mq($from: wide) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .project-excerpt {
        display: flex;
        flex-direction: column;
        gap: 10px;
        position: relative;
        width: 100%;
        color: $text-color;
        border: 1px solid #e4e4e4;

        &:hover, &:focus {
            text-decoration: none;
        }
    }

    .project-excerpt-wrapper {
        aspect-ratio: $project-excerpts-aspect-ratio;
        background-color: $brand-secondary;

        &.center {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
        }

        a {
            word-break: break-all;
        }
    }
}

.project-excerpt-video {
    width: 100%;
    display: block;
}

.project-excerpt-image {
    position: relative;
    display: block;
    width: 100%;
    aspect-ratio: $project-excerpts-aspect-ratio;
    overflow: hidden;
    background: $background-color center no-repeat;
    background-size: cover;
    text-indent: 100%;
    white-space: nowrap;

    &:after {
        content: '';
        position: absolute;
        z-index: 4;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 8px;
        transform: translateY(100%);
        background-color: $brand-primary;
        transition: transform 0.15s;
        pointer-events: none;
    }

    &:hover:after,
    &:focus:after,
    .project-excerpt-anchor:hover ~ &:after,
    .project-excerpt-anchor:focus ~ &:after {
        transform: translateY(0);
    }
}

.project-excerpt-icon {
    width: 40px;
    height: 40px;
    fill: $brand-primary;
}
