$select-height: 2.85em;

.select {
    position: relative;
    display: block;
    padding: 0;
    margin-bottom: 20px;
    border: 1px solid mix($border-color, $line-color, 50%);
    border-radius: $ui-border-radius;
    cursor: pointer;

    &:after {
        content: '';
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -2px;
        border: 4px solid transparent;
        border-top-width: 5px;
        border-top-color: mix(#fff, $text-color, 50%);
        pointer-events: none;
    }

    &:hover:after {
        border-top-color: $text-color;
    }

    select {
        height: $select-height;
        padding: 0.5em 2.5em 0.5em 0.75em;
        border: none;
        outline: none;
        appearance: none;
        background: none;

        &::-ms-expand {
            display: none;
        }

        &[disabled] {
            background: none;
            color: mix(#fff, $text-color, 50%);
        }
    }
}

.select2-container {
    height: $select-height;

    .select2-selection--single .select2-selection__rendered {
        padding: 0;
    }

    .select2-selection {
        height: $select-height;
        padding: 0.5em 0.75em;
        border-color: mix($border-color, $line-color, 50%);

        &:focus {
            border-color: $brand-primary;
            outline: none;
            box-shadow: none;
        }
    }

    .select2-selection__arrow {
        display: none;
    }

    .select2-container--default .select2-selection--single .select2-selection__rendered {
        line-height: $select-height;
    }

    &.select2-container--default {
        .select2-selection--single .select2-selection__clear {
            position: absolute;
            height: 32px;
            width: 32px;
            line-height: 32px;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            margin-right: 0.75em;
            padding: 0;
            border-radius: 100%;

            &:hover {
                background-color: $border-color;
            }
        }

        .select2-results__option--highlighted.select2-results__option--selectable {
            background-color: $brand-primary;
        }

        .select2-results__option--selected {
            background-color: $border-color;
        }
    }

    .select2-search__field {
        min-height: $select-height;
        padding: 0.5em 0.75em;
        border-radius: $ui-border-radius;
        box-shadow: none;
        background-color: #fff;

        &:focus {
            border-color: $brand-primary;
            outline: none;
            box-shadow: none;
        }
    }

    .select2-results__option.select2-results__message {
        color: #999;
    }
}
