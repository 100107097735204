.comment {
    position: relative;
    display: block;
    padding: 20px;
    margin-bottom: 20px;
    border-left: 4px solid $border-color;
    border-top: none;
    background-color: $background-color-lighter;
    font-size: $font-size-base;

    &:before {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 15px;
        border: 10px solid transparent;
        border-bottom-color: $background-color-lighter;
    }
}

.comment__add {
    width: 100%;
}
