$measure-costs-breakpoint: mobile !default;

// Preview
.measure-preview {
    position: relative;
    display: flex;
    height: 50vh;
}

.measure-preview-collapsed,
.measure-preview-expanded,
.measure-preview-placeholder {
    flex: 1 0 auto;
}

.measure-tgn-tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 2px 8px;
    font-size: 12px;
    color: #fff;
    fill: #fff;
    background-color: $brand-primary;
    text-wrap: nowrap;
  
    &.measure-tgn-tag-warning {
      background-color: $error-color;
  
      &:hover,
      &:focus {
        background-color: mix(#fff, $error-color, 15%);
      }
    }
  
    &:hover,
    &:focus {
      background-color: mix(#fff, $brand-primary, 15%);
      color: #fff;
      text-decoration: none;
    }
  }

// Details
.measure-details {
    margin-bottom: 30px;
}

.measure-title-container {
    margin-bottom: 0.5em;

    small {
        display: block;
        line-height: 1.5;
        font-size: 16px;
    }
}

.measure-title-wrapper { 
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.measure-title {
    display: block;
    margin-bottom: 0;
    font-weight: 500;
}

// Search
.measure-search {
    position: relative;
    display: flex;
    align-items: center;
}

.measure-search-icon {
    width: 32px;
    height: 32px;
    margin: 0 10px 0 0;
}

// Table of contents
.measure-toc {
    margin-top: 20px;

    @include mq($from: tablet) {
        margin-top: 2vw;
    }
}

.measure-toc-list {
    display: block;
    margin: 20px 0 0;
    padding: 0;

    li {
        display: block;

        &:first-child a {
            border-top: 1px solid $line-color;
        }
    }

    a {
        display: block;
        padding: 12px 0;
        border-bottom: 1px solid $line-color;
        color: $text-color;

        &:hover,
        &:focus {
            color: $brand-primary;
            text-decoration: none;
        }
    }
}

// Content
.measure-content {
    margin-top: 10px;

    img {
        display: block;
        max-width: 100%;
        margin: 1em auto;
    }

    &.is-discontinued {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: rgba(#fff, 0.6);
        }

        &:after {
            content: 'Vervallen';
            position: absolute;
            z-index: 1;
            top: 100px;
            left: 50%;
            transform: translateX(-50%) rotate(-10deg);
            font-size: 48px;
            font-weight: bold;
        }
    }
}

.measure-level-groups {
    dd span {
        display: block;
        margin-bottom: 5px;
    }
}

// Table
.measure-table {
    display: grid;
    grid-template-columns: 1fr;
    background: transparent;

    @include mq($from: desktop) {
        grid-template-columns: 70px auto 1fr max-content max-content repeat(5, max-content);

        &.has-force {
            grid-template-columns: 70px auto 1fr max-content max-content max-content repeat(5, max-content);
        }
    }

    thead {
        display: none;

        @include mq($from: desktop) {
            display: grid;
            grid-column: 1 / -1;
            grid-template-columns: subgrid;
        }
    }

    tbody {
        display: grid;
        grid-column: 1 / -1;
        grid-template-columns: subgrid;
    }

    tr {
        align-items: center;
        background-color: #fff;
        border-top: 4px solid $background-color-lighter;
        display: grid;
        grid-template-columns: repeat(5, 1fr);

        &.is-inactive {
            background: mix(#fff, $brand-primary, 97.5%);
            color: #fd474f;
        }

        @include mq($from: desktop) {
            display: grid;
            grid-column: 1 / -1;
            grid-template-columns: subgrid;
        }
    }

    .measure-table-column-thumbnail {
        text-align: center;

        @include mq($from: desktop) {
            text-align: initial;
        }
    }

    .measure-table-column-thumbnail, .measure-table-column-code, .measure-table-column-description, .measure-table-column-price, .measure-table-column-tgn, .measure-table-column-force {
        grid-column: 1 / -1;

        @include mq($from: desktop) {
            grid-column: auto;
        }
    }

    .measure-table-column-thumbnail, .measure-table-column-code, .measure-table-column-tgn {
        order: -1;

        @include mq($from: desktop) {
            order: initial;
        }
    }

    .measure-table-column-code {
        grid-column: 1 / 4;

        @include mq($from: desktop) {
            grid-column: auto;
            text-align: start;
        }
    }

    .measure-table-column-tgn {
        grid-column: 4 / -1;
        text-align: end;

        @include mq($from: desktop) {
            grid-column: auto;
            text-align: start;
        }
    }

    .show-small {
        @include mq($from: desktop) {
            display: none;
        }
    }

    .show-desktop {
        display: none;

        @include mq($from: desktop) {
            display: initial;
        }
    }

    tr {
        &:not(.measure-table-row-variant), &:not(.measure-table-row-variant-inactive-banner) {
            td {
                padding-left: 20px;
                padding-right: 20px;

                @include mq($from: desktop) {
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }
    }

    .span-rest {
        grid-column: 1 / -1;

        @include mq($from: desktop) {
            grid-column: 3 / -1;
        }
    }

    .force-group-container {
        @include mq($from: desktop) {
            display: inline-flex;
            flex-direction: column;
        }
    }

    small {
        display: inline-block;
        font-size: 14px;
        line-height: 1.6;
    }

    .measure-table-column-code {
        padding-left: 20px;
    }

    .measure-table-column-thumbnail {
        padding-right: 0;
    }

    > thead,
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                vertical-align: middle;
                border: none;

                &.measure-table-column-code,
                &.measure-table-column-force,
                &.measure-table-column-price {
                    white-space: nowrap;
                }

                &.measure-table-column-usp {
                    position: relative;
                    text-align: center;
                }
            }
        }
    }
}

.inactive-banner-container {
    display: flex;   
    align-items: center;

    p {
        margin: 0;
    }
}

.table tbody > tr.measure-table-row-variant-inactive-banner {
    td {
        padding-bottom: 0;
    }
}

.measure-table-row-variant, .measure-table-row-variant-inactive-banner {

    td {
        padding: 0;
    }

    .measure-table-column-thumbnail, .measure-table-column-description, .measure-table-column-price, .measure-table-column-usp {
        padding: 0;
    }

    .measure-table-column-code {
        padding: 0;
        padding-left: 20px;
    }

    .measure-table-column-tgn {
        padding: 0;
        padding-right: 20px;
    }

    .measure-table-column-force, .measure-table-column-description {
        padding: 0 20px;
    }

    .measure-table-column-thumbnail {
        height: 100%;
        background-color: $background-color;
    }

    &.is-inactive {
        .measure-table-column-thumbnail {
            height: 100%;
            background-color: $background-color;
        }
    }
}

.measure-table-row-variant-inactive-banner {
    .measure-table-column-code {
        padding-top: 20px;
    }
}

.measure-table-row-variant {
    .measure-table-column-code, .measure-table-column-tgn {
        padding-top: 20px;

        @include mq($from: desktop) {
            padding-top: 0;
        }
    }

    .measure-table-column-price {
        padding: 0 20px 20px;

        @include mq($from: desktop) {
            padding: 0 20px;
        }
    }

    &.is-inactive {
        border-top: 0;

        td {
            background: inherit;
            color: rgba($text-color, 0.5);
        }

        .measure-tgn-tag {
            background-color: rgba($brand-primary, 0.5);
      
            &.measure-tgn-tag-warning {
              background-color: rgba($error-color, 0.5);
            }
          }
    }
}
