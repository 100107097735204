.community-teaser-heading {
    margin-bottom: 1rem;
    flex: 1;

    @include mq($from: desktop) {
        margin-bottom: 0;
    }
}

.community-teaser-heading-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;

    .community-teaser-heading {
        margin-bottom: 0;
    }
}

.community-teaser-search-container {
    margin-bottom: 1rem;

    @include mq($from: desktop) {
        margin-bottom: 2rem;
    }
}

.community-teaser {
    .col-xs-12 + .col-xs-12 {
        margin-top: 1rem;
    }

    @include mq($from: desktop) {
        .col-xs-12 + .col-xs-12 {
            margin-top: 0;
        }
    }
}

.teaser-link-block {
    display: flex;
    align-items: center;
    background-color: $background-color;
    min-height: 50px;
    color: $text-color;

    & + .teaser-link-block {
        margin-top: 1rem;
    }

    &:hover {
        color: $link-color;
    }
}

.teaser-link-block-code {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    width: 50px;
    background-color: $ro-groen;
    color: #fff;
}

.teaser-link-block-title {
    flex: 1;
    padding: 0 16px;
    margin-bottom: 0;
    font-size: $font-size-base;
    color: inherit;
}

.teaser-link-block-icon {
    width: 32px;
    height: 32px;
    fill: $ro-groen;
}

.teaser, .community-teaser-heading-container {
    .btn {
        margin: 0;
    }
}

.teaser {
    padding: 1.5rem;
    border: 1px solid $line-color;
    text-align: center;

    .btn {
        + .btn {
            margin-left: 0;
        }
    }

    .btn-link {
        color: $text-color;

        .icon path {
            fill: $text-color;
        }
    }
}

.teaser-heading {
    font-weight: 700;
    font-size: 1.5rem;
}

.teaser-heading-small {
    font-size: 1.25rem;
}

@include mq($from: desktop) {
    .teaser {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem;
        gap: 2rem;
    }

    .teaser-heading {
        margin: 0;
    }
}
