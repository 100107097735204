.object-viewer {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: $background-color;

    canvas {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.object-floors {
    position: absolute;
    top: 0;
    left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    ul {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        overflow: auto;
        list-style: none;
    }

    li {
        &:first-child label {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }

        &:last-child label {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        + li label {
            border-top: 1px solid #e4e4e4;
        }
    }

    input {
        position: absolute;
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
        overflow: hidden;
        clip: rect(0 0 0 0);
    }

    label {
        display: block;
        width: 64px;
        margin: 0;
        padding: 20px 18px;
        border: none;
        background: #fff;
        cursor: pointer;
        font-size: 14px;
        line-height: 1;
        text-align: center;
        color: #6c6c6c;
        white-space: nowrap;

        &:hover,
        &:focus {
            color: $brand-primary;
        }
    }

    input:checked + label {
        background: $brand-primary;
        color: #fff;
    }
}

.object-details {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    border-top: 1px solid $line-color;
    transform: translateY(100%);
    transition: transform 0.4s;
    background-color: #fff;

    &.is-active {
        transform: translateY(0);
    }
}

.object-details-content {
    display: flex;
    overflow: auto;
}

.object-details-block {
    flex: 2;
    padding: 25px 20px;

    & + & {
        flex: 3;
        border-left: 1px solid $line-color;
    }

    @include mq($from: tablet) {
        padding: 30px 2.5vw;
    }

    dl {
        display: flex;
        flex-flow: column wrap;
        width: 100%;
        height: 100px;
        margin: 0;

        dt {
            height: 25%;
        }

        dd {
            height: 75%;
        }
    }
}
