.community-author {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    .initials-holder {
        margin-right: 1.5rem;
    }

    @include mq($from: desktop) {
        flex-direction: row;
    }
}

.community-author-name-holder {
    flex: 1 0;
}

.community-author-name {
    font-size: 20px;
}

.community-author-meta {
    color: #9f9f9f;
    font-size: 14px;
}

.community-author-chapters {
    margin-bottom: 1rem;
    font-size: 14px;

    ul {
        margin: 0;
        padding: 0;
    }

    li {
        display: inline-block;

        &:after {
            content: ' - ';
        }

        &:last-child {
            &:after {
                content: none;
            }
        }
    }
}

.community-author-chapters-heading {
    display: inline-block;
}

.community-author-actions {
    display: flex;
    align-items: center;

    .btn {
        margin: 0;
        padding-left: 20px;
        padding-right: 20px;

        &:after {
            content: attr(data-to-follow-text);
        }

        svg {
            vertical-align: middle;
        }

        .cancel {
            display: none;
        }

        &.is-watching {
            &:after {
                content: attr(data-following-text);
            }

            .cancel {
                display: none;
            }

            &:hover,
            &:focus {
                &:after {
                    content: attr(data-unfollow-text);
                }

                .cancel { // stylelint-disable-line selector-max-specificity
                    display: inline-block;
                }

                .check { // stylelint-disable-line selector-max-specificity
                    display: none;
                }
            }
        }
    }

    .btn-outline {
        svg,
        path {
            fill: $brand-primary;
        }

        &:hover,
        &:focus {
            svg,
            path {
                fill: #fff;
            }
        }
    }
}
