.fh-blocks {
    img {
        max-width: 100%;
    }

    .block.block-img {
        figure {
            display: inline-block;
        }

        figcaption {
            text-align: center;
            color: #9f9f9f;
            font-style: italic;

            p {
                margin: 0;
            }
        }
    }
}

.download-link {
    display: flex;
    align-items: center;
    gap: 6px;

    &:before {
      content: '';
      display: block;
      width: 13px;
      height: 13px;
      background-image: url($public-path + 'images/sprites/download-primary.svg');
      background-repeat: no-repeat;
      background-position: center;
    }

    svg {
        width: 13px;
        height: 13px;
        fill: currentColor;
        margin: 0;
    }

    &.inline-download-link {
        display: inline-flex;
    }
}
