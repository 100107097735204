.file-upload {
    position: relative;
    display: block;
    margin-bottom: 20px;
    padding: 18px 20px;
    border: 1px solid mix($border-color, $line-color, 50%);
    border-radius: $ui-border-radius;
    background-color: $background-color-lightest;

    &.is-empty {
        padding: 40px;
        color: mix($background-color-lightest, $text-color, 50%);
        text-align: center;
    }

    &:hover,
    &:focus {
        color: $brand-primary;
    }

    .btn {
        border: 1px solid $brand-primary;
        background-color: transparent;
        color: $brand-primary;
        fill: transparent;

        &:hover,
        &:focus {
            background: $brand-primary;
            color: #fff;

            .icon-trash {
                background-image: url("data:image/svg+xml,%3csvg fill='#fff' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e %3cpath d='M19%2c4H15.5L14.5%2c3H9.5L8.5%2c4H5V6H19M6%2c19A2%2c2 0 0%2c0 8%2c21H16A2%2c2 0 0%2c0 18%2c19V7H6V19Z'/%3e %3c/svg%3e");
            }
        }
    }

    .icon-trash {
        background-image: url("data:image/svg+xml,%3csvg fill='#{$brand-primary}' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e %3cpath d='M19%2c4H15.5L14.5%2c3H9.5L8.5%2c4H5V6H19M6%2c19A2%2c2 0 0%2c0 8%2c21H16A2%2c2 0 0%2c0 18%2c19V7H6V19Z'/%3e %3c/svg%3e");
    }

    .form-group.has-error & {
        border: 1px solid $error-color;
    }

    &.is-disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

.file-upload-input {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: none;
    opacity: 0;
    cursor: pointer;
}

.file-upload-preview.is-file {
    font-family: $font-family-sans-serif;
}
