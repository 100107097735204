.introjs-helperNumberLayer {
    background: $brand-primary;
}

.has-open-modal {
    .introjs-overlay {
        background: transparent;
    }

    .introjs-helperLayer {
        background: transparent;
    }
}

.introjs-prevbutton {
    display: none;
}

.introjs-skipbutton {
    display: none;
}

.introjs-donebutton {
    display: inline-block;
}
