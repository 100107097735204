.table {
    width: 100%;
    margin: 0 0 2em;
    font-weight: 300;

    > thead,
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                padding: 0.5em;
                border-bottom: 1px solid $border-color;
                text-align: left;
            }

            th {
                vertical-align: bottom;
                border-bottom-color: mix($text-color, $border-color, 10%);
                font-weight: 400;
            }

            td {
                vertical-align: top;
            }

            &:last-child td {
                border-bottom: none;
            }
        }
    }
}
