.tooltip {
    position: absolute;
    bottom: 100%;
    left: 50%;
    display: block;
    padding: 6px 12px;
    transform: translate(-50%, -5px);
    background-color: $text-color;
    color: #fff;
    font-size: 14px;
    white-space: nowrap;
    pointer-events: none;
    opacity: 0;
    transition: all 0.25s;
    transition-property: opacity, transform;

    .tooltip-trigger:hover &,
    .tooltip-trigger:focus & {
        transform: translate(-50%, -10px);
        opacity: 1;
        transition-delay: 0.25s;
    }

    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        display: block;
        margin-left: -5px;
        border: 5px solid transparent;
        border-top-color: $text-color;
    }
}

.tooltip-trigger {
    position: relative;
    z-index: 1;
    display: inline-block;
    cursor: help;

    .icon {
        z-index: -1;
        pointer-events: none;
    }
}
