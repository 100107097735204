@use "sass:math";

form {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 0.5em;
    font-weight: 300;
    font-size: 14px;
    color: #777;
}

input[type='text'],
input[type='search'],
input[type='password'],
input[type='email'],
input[type='tel'],
input[type='url'],
textarea,
select,
.form-control {
    display: block;
    width: 100%;
    height: auto;
    min-height: 2.85em;
    padding: 0.5em 0.75em;
    border: 1px solid mix($border-color, $line-color, 50%);
    border-radius: $ui-border-radius;
    box-shadow: none;
    background-color: #fff;

    &:focus {
        border-color: $brand-primary;
        outline: none;
        box-shadow: none;
    }
}

textarea.form-control {
    min-height: 7.5em;
    resize: vertical;
}

select.form-control {
    height: 2.85em;
}

// Form
.form {
    display: block;
    margin-bottom: 20px;
}

.form-content { // Modifier
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    margin: 0;
}

// Groups
.form-group {
    margin-bottom: 25px;

    &:last-of-type {
        margin-bottom: 0;
    }

    .btn + & {
        margin-top: 15px;
    }
}

// Controls
.form-control {
    &,
    .has-error & {
        &,
        &:focus {
            box-shadow: none;
        }
    }
}

// Select
.form-select {
    position: relative;
    display: block;
    padding: 0;
    cursor: pointer;

    &:after {
        content: '';
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -3px;
        border: 4px solid transparent;
        border-top-width: 5px;
        border-top-color: mix(#fff, $text-color, 50%);
        pointer-events: none;
    }

    &:hover:after {
        border-top-color: $text-color;
    }

    select {
        padding-right: 35px;
        appearance: none;

        &::-ms-expand {
            display: none;
        }

        &[disabled] {
            background: none;
            color: mix(#fff, $text-color, 50%);
        }
    }
}

// Notifications
.form-notifications {
    display: block;
    margin-bottom: 24px;

    &:empty {
        display: none;
    }
}

// Actions
.form-action {
    display: inline-block;
    margin-right: 8px;
}

.form-action-secondary { // Modifier
    &,
    &:hover,
    &:focus {
        color: mix(#fff, $text-color, 50%);
    }
}

// Help
.help-block,
.help-text {
    margin-top: 10px;
    font-size: 0.85em;
}

// Toggleables
.form-toggleable-blocks {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: -6px -8px 15px;
    padding-left: 0;
}

.form-toggleable-block {
    display: flex;
    flex-direction: column;
    width: 240px;
    margin: 6px 8px;

    // stylelint-disable selector-max-specificity
    input[type='radio'],
    input[type='checkbox'] {
        display: none;

        &:checked + .form-toggleable-block-label {
            padding: 14px 24px 11px 14px;
            border-color: $brand-primary;
            border-width: 2px;

            .form-toggleable-block-title {
                color: $brand-primary;
            }
        }

        &:disabled + .form-toggleable-block-label {
            &,
            &:hover,
            &:focus {
                border-color: mix(#fff, $line-color, 50%);
                background-color: mix(#fff, $background-color, 50%);
                color: mix(#fff, $text-color, percentage(math.div(2,3)));
                cursor: not-allowed;
            }
        }
    }
}

.form-toggleable-block-label {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    padding: 15px 25px 12px 15px;
    border: 1px solid mix(#fff, $line-color, 50%);
    border-radius: 4px;
    color: $text-color;
    cursor: default;

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }

    input[type='radio'] + &,
    input[type='checkbox'] + & {
        cursor: pointer;

        &:hover,
        &:focus {
            border-color: $line-color;
        }
    }

    .form-toggleable-block.is-discontinued & {
        border-color: #f0f0f0;
        background: #f0f0f0;
        color: #949494;
    }
}

.form-toggleable-block-title {
    display: block;
    margin-bottom: 2px;
    font-size: 18px;
    font-weight: 500;
}

.form-toggleable-block-description {
    display: block;
    margin-top: 2px;
    margin-bottom: 0;
    font-size: 15px;
    line-height: 1.65;
}

.form-toggleable-block-force-groups {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 15px;

    dt {
        font-weight: 600;
    }
}

.form-toggleable-block-price {
    display: block;
    margin-top: auto;
}

// File uploads
.file-upload {
    display: block;
    padding: 15px 20px 30px;
    margin-top: 10px;
    background-color: $background-color-lightest;

    .btn {
        @extend .btn;
        @extend .btn-secondary;

        &.is-hidden {
            display: none;
        }
    }
}

.file-upload-preview {
    margin: 20px 0;

    &.is-file {
        font-family: monospace;
    }
}

// Radio & checkbox
.radio,
.checkbox {
    display: block;

    &:hover,
    &:focus {
        label:before {
            box-shadow: 0 0 0 1px $brand-primary;
        }
    }

    input[type='radio'],
    input[type='checkbox'] {
        display: none;

        &:checked + label {
            &:before {
                border: 0.25em solid #fff;
                background-color: $brand-primary;
                box-shadow: 0 0 0 1px $brand-primary;
            }
        }
    }

    label {
        position: relative;
        display: inline-block;
        padding-left: 1.75em;
        font-size: 1em;
        font-weight: inherit;
        color: $text-color;

        &:before {
            content: '';
            position: absolute;
            top: 0.25em;
            left: 0;
            display: block;
            width: 1.2em;
            height: 1.2em;
            border-radius: 50%;
            background-color: #fff;
            box-shadow: 0 0 0 1px mix($border-color, $line-color, 50%);
        }
    }
}

.checkbox {
    label:before {
        border-radius: math.div($ui-border-radius, 2);
    }
}

// Checkbox toggle
.checkbox-toggle-state {
    display: inline-block;
    width: auto;

    &[type='checkbox'] {
        margin-right: 10px;
    }
}

.checkbox-toggle-label {
    display: inline-block;
    color: $text-color;
    font-size: $font-size-base;
}

.checkbox-toggle-content {
    display: none;
    margin-bottom: 25px;

    .checkbox-toggle-state:checked ~ & {
        display: block;
    }
}
