$notification-types: (
    error: $error-color,
    warning: $warning-color,
    success: $success-color,
    info: $info-color
);

.notifications {
    display: block;
    width: 100%;
    margin: 0 auto;
    padding: 15px 0 0;

    &:empty {
        display: none;
    }
}

.notification {
    position: relative;
    display: block;
    padding: 14px 3em 14px 18px;
    margin-bottom: 10px;
    color: #fff;

    &:last-child {
        margin-bottom: 0;
    }

    @each $type, $color in $notification-types {
        &.is-#{$type} {
            background-color: $color;
        }
    }
}

.notification-icon {
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    margin-top: -4px;
    fill: #fff;

    &:first-child {
        margin-right: 10px;
    }
}

.notification-close {
    position: absolute;
    top: 0.325em;
    right: 0.325em;
    display: block;
    width: 32px;
    height: 32px;
    padding: 4px;
    border: none;
    outline: none;
    background: none;
    opacity: 0.5;
    font-size: 2em;
    font-weight: 300;
    line-height: 0;

    &:hover,
    &:focus {
        opacity: 1;
    }
}
