.footer {
    width: 100%;
    padding: 12px;
    background-color: $brand-secondary;
    font-size: 14px;
    text-align: center;
}

.footer-link {
    display: inline-block;
    padding: 0 10px;
    color: $ro-donkergroen;

    &:hover {
        color: $ro-donkergroen;
        text-decoration: underline;
    }
}
