/* stylelint-disable */

.table {
    background: #fff;

    thead,
    tbody,
    tfoot {
        > tr {
            th,
            td {
                padding: 10px;
                text-align: left;

                @include mq($until: mobile) {
                    display: block;
                    width: 100%;
                    padding: 12px 0;
                    margin-bottom: -1px;
                }
            }

            &.table-row-notification {
                td {
                    padding: 0;
                }

                + tr {
                    td {
                        background: #f7e3e2;
                    }
                }
            }

            &.empty-row > td {
                position: relative;
                padding: 0 25px;
                border: none;
            }
        }
    }

    thead {
        @include mq($until: mobile) {
            display: none;
        }
    }

    .is-inactive td {
        background: mix(#fff, $brand-primary, 97.5%);
        color: #fd474f;
    }

    .toggleable-row:hover > td {
        cursor: pointer;
        background: $background-color-lightest;
    }

    .toggleable-row-icon {
        width: 30px;
        padding-right: 5px;

        .icon {
            position: relative;
            top: -1px;
            left: 3px;
        }
    }

    .toggleable-row.is-expanded .icon-expand {
        transform: rotate(180deg);
    }

    .toggleable-content {
        padding-top: 20px;
        padding-bottom: 25px;
        margin: 0;

        &.table {
            margin-top: 10px;
            margin-bottom: 10px;
        }

        > *:first-child {
            margin-top: 0;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }

    .toggleable-notification {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 15px 20px 14px;
        text-align: center;
        background-color: $brand-primary;
        color: #fff;
        fill: #fff;

        + .toggleable-content {
            padding-top: 80px;
        }
    }

    .toggleable-notification-secondary { // Modifier
        background-color: $brand-secondary;
        color: $text-color;
        fill: $text-color;
    }

    .icon {
        position: relative;
        top: -1px;
        display: inline-block;
        vertical-align: middle;
    }
}

.table-default {
    margin: 40px 0;

    thead > tr th {
        background: $background-color-lighter;
    }
}

.table-forces-section {
    & + & {
        padding-top: 2rem;
    }

    p {
        margin: 0;
    }

    .table {
        margin: 20px 0;
    }
}
