@import 'fh-redactor/lib/redactorIII/scss/redactor.scss';

.notification {
    margin-bottom: 2rem;
    border: 1px solid;
    color: $body-color;
    text-align: center;
    font-size: 14px;
    font-weight: 400;

    &:last-child {
        margin-bottom: 2rem;
    }

    &:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 25px;
        height: 25px;
        margin-right: 10px;
        background-repeat: no-repeat;
        background-size: 25px;
    }

    &.is-warning {
        border: 1px solid #ffaa01;
        background-color: #fff8e8;

        &:before {
            background-image: url($public-path + 'images/sprites/warning.svg');
        }
    }

    &.is-success {
        border-color: #00a000;
        background-color: #e5ffec;

        &:before {
            background-image: url($public-path + 'images/sprites/check-circled.svg');
        }
    }

    &.is-error {
        border-color: #ab0000;
        background-color: #ffe8e9;

        &:before {
            background-image: url($public-path + 'images/sprites/cancel-circled.svg');
        }
    }

    &.is-info {
        border-color: #b8daff;
        background-color: #cce5ff;

        &:before {
            background-image: url($public-path + 'images/sprites/info.svg');
        }
    }
}

.content {
    > .notification {
        position: absolute;
        z-index: 999;
        top: 98px;
        left: 50%;
        width: 100%;
        max-width: 800px;
        margin: 0;
        transform: translateX(-50%);
    }
}

.notification-close {
    opacity: 1;
    color: $body-color;
}

.notifications {
    padding: 0;
}

.notification-viewer {
    width: 100%;
    margin: 0 auto;

    &.is-loading {
        cursor: progress;

        a {
            pointer-events: none;
            cursor: progress;
        }
    }
}

.notification-status {
    border: 1px solid #ccc;
    background: #f6f7f5;
    color: $body-color;
}
