@use "sass:math";

.community-page {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 0 -1rem;
}

.community-page-actions {
    @include addActionStyles;
    display: flex;
    align-items: center;
    width: 100%;
}

.community-page-menu {
    margin-bottom: 2rem;
}

.community-page-heading-holder {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 1rem;

    h1, .heading {
        margin: 0;
    }

    h1.heading {
        font-size: 24px;
    }

    .community-page-actions {
        order: -1;
        flex: 1 0 75px;
        margin: 0;
    }
}

.community-page-heading {
    margin: 0;
}

.community-page-sub-heading {
    margin-bottom: 2rem;
}

.community-page-actions-help {
    margin-bottom: 1rem;
    color: #9f9f9f;
    font-size: 14px;
}

.community-page-content-filter-holder {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 1rem;
}

.community-page-sort-holder {
    margin-left: auto;
}

.community-page-filter-holder {
    flex: 1;
    margin-bottom: 0;
}

@include mq($from: tablet) {
    .community-page-heading-holder {
        .community-page-actions {
            order: 1;
        }
    }
}

@include mq($from: desktop) {
    .community-page {
        flex-direction: row;
    }

    .community-page-centered {
        justify-content: center;
    }

    .community-page-menu {
        width: percentage(math.div(3, 12));
        margin: 0;
        padding: 0 1rem;
    }

    .community-page-content {
        width: percentage(math.div(9, 12));
        padding: 0 1rem;
    }

    .community-page-actions {
        justify-content: flex-end;
        margin-bottom: 2rem;
    }

    .community-page-heading-holder {
        flex-wrap: nowrap;

        .community-page-actions {
            order: 1;
            padding-top: 5px;
        }
    }
}

@include mq($from: wide) {
    .community-page-centered {
        .community-page-content {
            width: percentage(math.div(8, 12));
        }
    }

    .community-page-heading-holder {
        h1.heading {
            font-size: 32px;
        }
    }
}
