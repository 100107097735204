.breadcrumbs {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    font-size: 12px;
    color: #fff;

    @media (min-height: 1300px) and (min-width: $screen-xlg) {
        font-size: 14px;
    }

    > ol {
        display: flex;
        align-items: center;
    }
}

.breadcrumbs-static {
    position: static;
}

.breadcrumbs-list {
    margin: 0;
    padding: 0;
}

.breadcrumbs-list-item {
    position: relative;
    display: inline-flex;
    align-items: center;
    margin: 0 12px;

    &:first-child {
        a {
            display: inline-block;
            vertical-align: middle;

            &:before {
                content: '';
                display: block;
                width: 15px;
                height: 15px;
                background-image: url($public-path + 'images/sprites/home.svg');
                background-size: 15px 15px;
            }
        }

        span {
            display: none;
        }
    }

    &:after {
        content: '›';
        position: absolute;
        right: -14px;
        top: 50%;
        display: inline-block;
        transform: translateY(-50%);
        opacity: 0.25;
        font-size: 18px;
        color: #fff;
    }

    &:last-child {
        &:after {
            content: none;
        }
    }

    &.is-open {
        cursor: pointer;

        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            right: -6px;
            border-style: solid;
            border-width: 0 10px 10px 10px;
            border-color: transparent transparent $brand-secondary transparent;
        }

        > span:hover {
            text-decoration: underline;
        }
    }

    a {
        color: #fff;

        &:hover {
            text-decoration: underline;
        }
    }

    > a,
    > span {
        display: block;
        max-width: 400px;
        padding: 0.75rem 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.breadcrumbs-search-trigger {
    width: 17px;
    height: 17px;
    margin-left: 5px;
    margin-right: -5px;
    padding: 0;
    border: 0;
    background-color: transparent;
    background-image: url($public-path + 'images/sprites/search.svg');
    transition: 0.2s transform;

    &:hover {
        transform: scale(1.2);
    }
}
