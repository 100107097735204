@use "sass:math";

.homepage-usp-item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    & + & {
        margin-top: 1rem;
    }
}

.homepage-usp-content {
    margin-top: 1rem;
}

.homepage-usp-image-holder {
    order: -1;
}

.homepage-usp-image {
    width: 100%;
}

.homepage-usp-heading {
    font-weight: 700;
    font-size: 24px;
}

.homepage-usp-holder {
    > .container {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@include mq($from: desktop) {
    .homepage-usp-item {
        margin: 0 -20px;

        & + & {
            margin-top: 3rem;
        }
    }

    .homepage-usp-item-image-left {
        .homepage-usp-image-holder {
            order: -1;
        }
    }

    .homepage-usp-content {
        margin-top: 0;
    }

    .homepage-usp-content,
    .homepage-usp-image-holder {
        width: 50%;
        padding: 0 20px;
    }

    .homepage-usp-image-holder {
        order: 0;
        width: 50%;
    }

    .homepage-usp-holder {
        > .container {
            padding-left: 50px;
            padding-right: 50px;
        }
    }

    .homepage-usp-heading {
        font-size: 32px;
    }
}

@include mq($from: wide) {
    .homepage-usp-item-image-right {
        padding-left: percentage(math.div(1, 12));
    }

    .homepage-usp-item-image-left {
        padding-right: percentage(math.div(1, 12));
    }
}
