@use "sass:math";
$auth-breakpoint: 480px !default;

.auth {
    display: flex;
    width: 100%;
    min-height: 100vh;
}

.auth-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1 percentage(math.div(1, 3));
    width: 100%;
    padding: 40px;

    @include mq($from: $auth-breakpoint) {
        width: auto;
        min-width: $auth-breakpoint;
        max-width: 100%;
    }

    @include mq($from: 800px) {
        padding: 4vw;
    }
}

.auth-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.auth-image {
    display: none;
    flex: 1 1 percentage(math.div(2,3));
    background: $background-color center no-repeat;
    background-size: cover;

    @include mq($from: $auth-breakpoint) {
        display: block;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.auth-title {
    margin-bottom: 0.75em;
}
