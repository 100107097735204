@use "sass:math";

.card-list {
    display: block;

    .card {
        &:first-of-type {
            margin-top: $card-space;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.card-list-item {
    display: block;
    padding: $card-space 0;
    border-bottom: 1px solid $line-color;
    overflow: hidden;
}

.card-list-toggleable { // Modifier
    .card {
        display: none;
    }

    .card-list-item-state:checked ~ .card {
        display: block;
    }
}

.card-list-item-header {
    position: relative;
    display: flex;
    align-items: center;
    margin: -5px (math.div(-$grid-gutter-width, 4));

    > * {
        padding: 5px (math.div($grid-gutter-width, 4));
    }

    .usps {
        flex: 1 0 auto;
    }

    a,
    button {
        position: relative;
        z-index: 2;
    }
}

.card-list-item-header-details {
    flex: 0 1 auto;
    max-width: 360px;
}

.card-list-item-title {
    margin-bottom: 0.2em;
    font-size: 24px;
    font-weight: 500;
}

.card-list-item-toggle {
    color: $text-color;
    fill: $text-color;
    cursor: pointer;

    &:hover,
    &:focus {
        color: $brand-primary;
        fill: $brand-primary;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.card-list-item-toggle-icon {
    display: block;
    width: 30px;
    height: 30px;
}
