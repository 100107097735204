.toggle-block-state {
    display: none;
}

.toggle-block-header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    border-bottom: 2px solid $background-color;

    .completed-checkbox {
        width: 25px;
        height: 25px;
        margin-right: 1rem;
        border-radius: 4px;
        background-color: #ccc;
        fill: #fff;
    }
}

.toggle-block-header-title {
    margin: 0 auto 0 0;
    padding-right: 25px;

    &,
    label {
        display: block;
        margin-bottom: 0;
        color: $text-color;
        font-size: 22px;
        font-weight: 700;
    }
}

.toggle-block-header-actions {
    display: flex;
    margin: 0;
    padding: 0;

    li,
    a {
        display: block;
    }
}

.toggle-block-header-action {
    display: block;
    margin: 0 2px 0 0;
    padding: 0;
    border: 0;
    cursor: pointer;
    color: mix(#fff, $text-color, 75%);

    &:hover,
    &:focus {
        color: $brand-primary;
    }

    svg {
        fill: currentColor;
    }
}

.toggle-block-header-action-icon {
    display: block;
    width: 50px;
    height: 50px;
    padding: 14px;
}

.toggle-block-header-toggle {
    position: relative;
    display: block;
    margin: 0;
    font-weight: 300;
    font-size: 22px;
    color: $text-color;
    cursor: pointer;
}

.toggle-block-header-toggle-icon {
    display: block;
    width: 50px;
    height: 50px;
    padding: 8px;
    margin-bottom: -2px;
    background-color: $background-color;
    fill: $brand-primary;

    .toggle-block-state:checked ~ .toggle-block-header & {
        transform: rotate(180deg);
    }
}

.toggle-block-content {
    display: none;
    margin-bottom: 4em;

    .toggle-block-state:checked ~ &,
    .catalog-detail.tab-content--active & {
        display: block;
    }
}

.js-construction-import-icon-method,
.js-construction-import-icon-ifc,
.js-construction-import-icon-forces {
    &.is-completed {
        .completed-checkbox {
            background: #75ee3a;
            fill: #fff;
        }
    }
}
