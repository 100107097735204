$navigation-line-color: #ededed;

.community-navigation {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li {
        padding: 0.75rem 0;
        border-bottom: 1px solid $navigation-line-color;

        &:first-child {
            border-top: 1px solid $navigation-line-color;
        }

        &.current {
            padding-left: 1.5rem;
            border-left: 5px solid $brand-primary;

            a {
                font-weight: 700;
            }
        }
    }

     a {
         color: $text-color;

         &:hover {
             color: $brand-primary;
             text-decoration: none;
         }
     }
}

.community-navigation-heading {
    margin-bottom: 1rem;
    margin-top: 1rem;
    font-size: 14px;
}
