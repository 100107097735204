@use "sass:math";

.tutorial {
    display: block;
    padding: 0;
}

.tutorial-heading {
    display: flex;
    margin-bottom: 0;

    svg {
        vertical-align: middle;
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }
}

.tutorial-step {
    display: flex;
    align-items: center;
    padding: 15px 0;
    font-size: 12px;

    & + & {
        padding-top: 0;
    }
}

.tutorial-step-icon {
    display: block;
    margin-right: 15px;

    svg {
        display: block;
        width: 30px;
        height: 30px;
    }

    @media (min-height: 1300px) and (min-width: $screen-xlg) {
        margin-right: 25px;

        svg {
            width: 40px;
            height: 40px;
        }
    }
}

.tutorial-step-content {
    display: block;
    margin-bottom: 0;

    > *:last-child {
        margin-bottom: 0;
    }
}

.tutorial-step-title {
    margin-bottom: 2px;
    font-size: 12px;
    font-weight: normal;

    @media (min-height: 1300px) and (min-width: $screen-xlg) {
        font-size: 14px;
    }
}

// Icons
.tutorial-step-icon-mouse-left .mouse-button-left {
    fill: $brand-primary;
}

.tutorial-step-icon-mouse-right .mouse-button-right {
    fill: $brand-primary;
}

.tutorial-step-icon-mouse-scrollwheel .mouse-wheel {
    fill: $brand-primary;
}

.tutorial-step-icon-keyboard-arrows .keyboard-arrows {
    fill: $brand-primary;
}

.tutorial-step-icon-keyboard-shift .keyboard-shift {
    fill: $brand-primary;
}

.tutorial-step-icon-keyboard-wasd .keyboard-wasd {
    fill: $brand-primary;
}

.tutorial-step-icon-keyboard-rf {
    .keyboard-e,
    .keyboard-c {
        fill: $brand-primary;
    }
}

.tutorial-body {
    padding: $ui-whitespace $ui-whitespace 0;
    text-align: center;
}

.tutorial-image-container {
    padding: 30px 30px 0;
    display: flex;
    align-items: flex-end;
    background-color: $background-color;

    @include mq($until: tablet) {
        display: none;
    }

    img {
        width: 100%;
        object-fit: contain;
        object-position: bottom;
    }
}

.tutorial-image-a-r {
    position: relative;
    width: 100%;

    &:before {
        content: "";
        display: block;
        width: 100%;
        padding-top: (math.div(44, 105)) * 100%;
    }

    img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

.tutorial-slide {
    display: none;

    &.active {
        display: initial;
    }
}

.js-tutorial-dots {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-top: 16px;
    padding-bottom: $ui-whitespace;
}

.js-tutorial-dot {
    width: 8px;
    height: 8px;
    margin: 4px;
    border-radius: 99px;
    background-color: $brand-primary-lightest;

    &.active {
        background-color: $brand-primary;
    }
}
