.documentation-holder {
    > .container {
        width: auto;
        padding-left: 20px;
        padding-right: 20px;
    }
}

.documentation-list {
    margin: 0 -20px;
}

.documentation {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.documentation-content {
    flex: 1 0 auto;
    border-bottom: 1px solid $border-color;
}

.documentation-heading {
    font-weight: 500;
    font-size: 30px;
}

.documentation-actions {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    margin-top: 20px;
}

@include mq($from: desktop) {
    .documentation-holder {
        > .container {
            padding-left: 50px;
            padding-right: 50px;
        }
    }

    .documentation {
        width: 50%;
        padding: 0 20px;
    }

    .documentation-list {
        display: flex;
        flex-wrap: wrap;
    }
}

@include mq($from: wide) {
    .documentation-holder {
        > .container {
            width: 1240px;
        }
    }
}
