.breadcrumbs-search {
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0;
    display: none;
    width: 450px;
    padding: 2rem;
    background: $brand-secondary;
    font-size: 14px;

    .breadcrumbs-search-input {
        padding: 1em;
        border: 0;
        border-radius: 0;
        background-color: $brand-secondary-light;
        color: #fff;

        &::placeholder {
            font-style: italic;
            color: #fff;
        }
    }

    .breadcrumbs-list-item.is-open & {
        display: block;
    }
}

.breadcrumbs-search-list {
    max-height: 300px;
    margin: 2rem 0 0 0;
    padding: 0;
    overflow-y: scroll;
    list-style: none;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    a {
        display: block;
        padding: 1rem 0;
        border-bottom: 1px solid $brand-secondary-light;
    }

    li:first-child {
        a {
            padding-top: 0;
        }
    }
}

.breadcrumbs-search-list-empty {
    margin-top: 1rem;
}
