.building-list {
    width: 100%;
    padding: 2rem 3rem;
    margin: 0 0 2.5rem;
    border: 1px solid #dbdbdb;
    transition: 0.2s opacity;
}

.building-list__test-marker {
    display: inline-block;
    padding: 4px 8px 3px;
    border-radius: 3px;
    background: #d1d1d1;
    color: #6e6e6e;
}

.building-list__client {
    position: relative;
    display: inline-block;
    float: right;
    width: 50px;
    height: 50px;
    margin-right: 2rem;
    border-radius: 50%;
    font-size: 1.2rem;
    text-align: center;
    font-weight: bold;
    line-height: 50px;
    background: #dbdbdb;
    transition: all 0.2s;
}

.building-list__engineer {
    position: absolute;
    right: 0;
    top: 50%;
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    transform: translateY(-50%);
    text-align: center;
    line-height: 40px;
    font-weight: bold;
    background: #d1d1d1;
    color: #6e6e6e;
    transition: all 0.2s;
}

.building-list__header {
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid #dbdbdb;
}

.building-list__heading {
    flex: 1 0 auto;
    margin: 0;
    font-weight: bold;

    &:hover .building-list__heading-link {
        text-decoration: none;
    }
}

.building-list__heading-link {
    color: #1c1c1c;

    &:focus,
    &:hover {
        text-decoration: none;
    }
}

.building-list-header-action {
    display: flex;
    align-items: flex-end;

    .text {
        width: 75%;
    }

    .action {
        flex: 1 0 auto;
    }
}

.building-list__actions {
    position: relative;
}

.building-list__action-trigger {
    width: 30px;
    height: 20px;
    padding: 0;
    border: 0;
    background-color: transparent;
    background-image: url($public-path + 'images/sprites/kebab-menu-horizontal.svg');
    background-repeat: no-repeat;
    background-position: center;
    text-indent: -99999px;
}

.building-list__action-list {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 30px;
    display: none;
    padding: 0;
    border: 1px solid #b8b8b8;
    list-style-type: none;
    background: #fff;

    &.is-open {
        display: block;
    }
}

input[type='checkbox']:checked + .building-list__action-list {
    display: block;
}

.building-list__action-item {
    a {
        display: block;
        padding: 12px 15px;
        border-bottom: 1px solid #b8b8b8;
        white-space: nowrap;
        color: #595959;
        font-size: 12px;

        &:hover {
            text-decoration: none;
            color: $brand-primary;
        }
    }

    &:last-child a {
        border: 0;
    }
}

.building-list__details {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 1rem 0;
    border-bottom: 1px solid #dbdbdb;

    &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
    }
}

.building-list__column-title {
    position: relative;
    width: 100%;
    padding-right: 1rem;

    @include mq($from: desktop) {
        width: 320px;
    }

    @include mq($from: wide) {
        width: 450px;
    }
}

.building-list__title {
    color: #212121;

    &:hover,
    &:focus {
        color: $brand-primary;
        text-decoration: none;
    }
}

.building-list__status {
    display: inline-block;
    padding: 0 8px;
    border-radius: 3px;
    color: #fff;
    background: #3ba6ed;
}

.building-list__column-status {
    display: flex;
    flex: 1 0 auto;
}

.building-list__column-links {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-left: 1rem;

    @include mq($from: desktop) {
        width: 30%;
    }

    a {
        padding: 0 0 0 0.5rem;
        white-space: nowrap;

        &:not(:last-child) {
            padding: 0 0.5rem;
            border-right: 1px solid #000;
        }
    }
}

.building-list__edit-date {
    display: block;
    color: #a6a6a6;
    font-size: 0.85rem;
}
