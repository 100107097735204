.chart {
    height: 400px;
    margin-bottom: 40px;
}

.chart-explanation {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;
}

.chart-explanation-type {
    width: 45px;
    height: 18px;
    margin-right: 10px;
}

.chart-explanation-description {
    width: 100%;
}
