html,
body {
    height: 100%;
}

body {
    position: relative;
    min-height: 100%;
    fill: $text-color;

    &.has-3d-viewer {
        width: 100vw;
        height: 100vh;
        overflow: hidden;

        #jsd-widget { // stylelint-disable-line
            bottom: 0 !important;
            left: 0 !important;
        }
    }

    &.is-locked {
        position: fixed;
        left: 0;
        width: 100%;
    }
}

.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    min-height: 100%;
}

.wrapper-fill { // Modifier
    height: 100%;
}

.main {
    height: 100%;
}

.content {
    flex: 1 0 auto;

    .wrapper-fill & {
        flex: 1;
    }
}

hr {
    display: block;
    width: 100%;
    height: 1px;
    border: none;
    background-color: $border-color;

    h1 + &,
    h2 + &,
    h3 + & {
        margin-top: 0;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
   font-weight: $headings-font-weight;
}

.container-fluid {
    padding-left: 20px;
    padding-right: 20px;
}

a {
    &.has-icon {
        display: inline-flex;
        align-items: center;
    }

    svg {
        display: inline-block;
        margin-right: 5px;
        fill: $brand-primary;
    }

    &.btn:not(.btn-outline) {
        svg {
            fill: #fff;
        }
    }

    &.btn.btn-outline:hover {
        svg {
            fill: #fff;
        }
    }

    &[disabled] {
        opacity: 0.5;
        pointer-events: none;
    }
}

.is-hidden {
    display: none !important;
}
