.modal {
    z-index: 101;
    display: none;
}

.modal-trigger {
    display: inline-block;

    .btn {
        margin-bottom: 0;
    }

    &:hover {
        cursor: pointer;

        .btn-outline {
            background-color: $brand-primary;
            color: #fff;
            fill: #fff;
            pointer-events: none;
        }
    }

    &.is-open {
        ~ .modal {
            display: block;
        }
    }
}

.modal-trigger-sibling {
    &.is-open {
        ~ .modal {
            display: none;
        }

        + .modal {
            display: block;
        }
    }
}

.modal-is-open {
    display: block;

    .modal {
        display: block;
    }
}

.modal-actions {
    display: flex;
    align-items: center;
    margin-top: 30px;

    .btn {
        margin-bottom: 0;
        margin-right: 20px;
    }
}

.modal-content {
    margin: 0 auto;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.modal-content-container {
    white-space: normal;
}
