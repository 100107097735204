.result-comment-holder {
    width: 100%;
    margin: 30px 0 40px 0;
}

.result-comment {
    padding: 20px 0;
    border-bottom: 1px solid #ccc;

    &:first-child {
        border-top: 1px solid #ccc;
    }

    p:last-child {
        margin: 0;
    }
}

.result-comment-form {
    .control-label {
        font-weight: 700;
    }

    .btn {
        margin-top: 30px;
    }
}

.result-comment-header {
    display: flex;
    margin-bottom: 5px;
}

.result-comment-user {
    font-weight: bold;
}

.result-comment-date {
    color: #ccc;

    &:before {
        content: '|';
        display: inline-block;
        margin: 0 10px;
    }
}

.result-comment--animate {
    background: #fff;
    animation: slideIn 0.3s;
}

@keyframes slideIn {
    from {
        transform: translateY(150%) scale(1.2);
        opacity: 0;
    }

    to {
        transform: translateY(0) scale(1);
        opacity: 1;
    }
}
