.tabs {
    display: block;
    width: 100%;
    padding-right: 25px;
    margin: 0;
    border-bottom: 1px solid $border-color;

    ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
    }

    li {
        display: block;
        margin-right: 30px;
        white-space: nowrap;

        &:last-child {
            margin: 0;
        }
    }

    + .project-excerpts {
        display: none;
    }
}

.tab {
    display: block;
    margin: -6px 0 -1px;
    padding: 12px 0;
    border-bottom: 2px solid transparent;
    color: $text-color;

    &:hover,
    &:focus {
        outline: none;
        text-decoration: none;
    }

    &.is-active {
        border-bottom-color: $brand-primary;
        color: $brand-primary;
    }
}

.tabs-carousel {
    padding-right: 0;

    ul {
        display: block;
    }

    .flickity-prev-next-button {
        width: 25px;
        height: 25px;

        &.next {
            right: -25px;
        }

        &.previous {
            left: -25px;
        }
    }
}

.tabs-large {
    font-size: 18px;
}

.tab-content {
    display: none;
    padding: 20px 0;

    &--active {
        display: block;
    }

    @include mq($from: tablet) {
        padding: 40px 0;
    }
}

.tab-disabled {
    opacity: 0.25;
}
