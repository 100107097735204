.building {
    padding: 5rem 25px;
}

.building__top {
    display: flex;
    margin: 0 0 2rem 0;
}

.building__text {
    margin: 2rem 0;
}

.building__image-wrapper {
    width: 50%;
    margin-right: 2rem;

    img {
        max-width: 100%;
    }
}

.building__edited {
    color: #aaa;
    font-size: 14px;
}

.building__info-wrapper {
    width: 50%;

    h1 {
        font-weight: bold;
    }
}

.building__info {
    margin-bottom: 1rem;
}

.building__tab-holder {
    margin-top: 4rem;
}

.building__text-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 0 2rem;
    padding: 2rem;
    border: 1px solid #e4e4e4;

    p {
        width: 100%;
        padding-right: 1rem;
    }

    .heading {
        font-weight: 700;
    }

    .btn {
        margin: 0;
    }
}

.building__text-content {
    padding-right: 2rem;

    .notification {
        text-align: left;
    }

    > *:last-child {
        margin: 0;
    }
}

.building__notification {
    margin-bottom: 2rem;
    font-size: 14px;
    text-align: left;

    &:last-child {
        margin-bottom: 2rem;
    }

    .notification-icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;

        path {
            fill: $body-color;
        }
    }

    &:before {
        content: none;
    }
}

.building__label-info {
    display: inline-block;
    margin-right: 10px;
}

.no__assignments {
    width: 100%;
    margin-bottom: 4rem;
    text-align: center;

    p {
        font-size: 1.2rem;
        color: #a6a6a6;
    }
}

.building__phase-actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}

.building__phase-action {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin: 0;
    padding: 4px 10px;
    border-width: 1px;
    white-space: initial;
    font-size: 12px;

    svg {
        position: absolute;
        top: 50%;
        width: 15px;
        margin: 0;
        transform: translateY(-50%);
    }
}

.building__phase-action-back {
    padding-left: 35px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    svg {
        left: 15px;
    }
}

.building__phase-action-forward {
    padding-right: 35px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    svg {
        right: 15px;
        transform: translateY(-50%) rotate(180deg);
    }
}

.building__phase-action-blank {
    border-color: #ccc;
}
