@font-face {
    font-family: 'ROsans';
    src: url('#{$font-path}ROsanswebtextregular.woff') format('woff');
    font-style: normal;
    font-display: fallback;
    font-weight: 400;
}

@font-face {
    font-family: 'ROsans';
    src: url('#{$font-path}ROsanswebtextbold.woff') format('woff');
    font-style: normal;
    font-display: fallback;
    font-weight: 700;
}

@font-face {
    font-family: 'ROsans';
    src: url('#{$font-path}ROsanswebtextitalic.woff') format('woff');
    font-style: italic;
    font-display: fallback;
    font-weight: 400;
}

html {
    font-size: $font-size-base;
    line-height: $line-height-base;
}

body {
    font-weight: 300;
    color: $text-color;
    fill: $text-color;
}

// Text selection
::selection {
    background-color: rgba($brand-primary, 0.15);
}

// Headings
h1,
h2,
h3,
h4 {
    display: block;
    margin: 0 0 0.5em;
    font-family: $font-family-brand;
    font-weight: 300;

    em {
        font-style: normal;
        color: $brand-primary;
    }
}

// Anchors
a {
    color: $brand-primary;

    &:hover,
    &:focus {
        color: $brand-primary;
        text-decoration: underline;
    }
}

// Block-level elements
p {
    margin: 0 0 1em;
}

// Inline elements
b,
strong {
    font-weight: bold;
}
