@use "sass:math";
$card-space: 25px !default;

.card {
    display: block;
    padding: $card-space;
    border: 1px solid $line-color;
}

.card-header {
    padding: $card-space;
    margin: 0 (-$card-space) $card-space;
    border-bottom: 1px solid $line-color;

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }

    &:first-child {
        margin-top: -$card-space;
    }
}

.card-link {
    color: inherit;

    &:hover {
        color: inherit;
        text-decoration: none;

        .card-title {
            color: $brand-primary;
        }
    }
}

.card-title {
    display: block;
    margin: 0 0 0.25em;
    font-weight: 500;
    font-size: 32px;
}

.card-content-details {
    display: none;
    padding-top: $card-space * math.div(3,4);

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }

    .card-content-details-state:checked ~ & {
        display: block;
    }
}

.card-content-details-toggle {
    color: $text-color;
    font-size: $font-size-base;
    cursor: pointer;

    &:hover,
    &:focus {
        color: $brand-primary;
        fill: $brand-primary;
    }
}

.card-content-details-toggle-icon {
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    margin-top: -1px;

    .card-content-details-state:checked ~ .card-content-actions & {
        transform: rotate(180deg);
    }
}

.card-content-actions {
    display: flex;

    > * {
        margin-right: 10px;

        &:last-child {
            margin-left: auto;
            margin-right: 0;
        }
    }
}

.card-footer {
    margin: 0 (-$card-space);
    border-top: 1px solid $line-color;

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }

    &:last-child {
        margin-bottom: -$card-space;
    }
}
