.dl {
    @extend .clearfix;
    display: block;

    dt {
        float: left;
        clear: left;
        width: 120px;
        font-weight: 500;
    }

    dd {
        float: left;
    }
}

.dl-inline { // Modifier
    dt {
        clear: none;
        width: auto;
        margin-right: 8px;
        font-weight: 300;
    }

    dd {
        margin-right: 35px;
        font-weight: 500;

        &:last-child {
            margin-right: 0;
        }
    }
}

.dl-columns { // Modifier
    margin: 0;

    dt {
        clear: left;
        width: calc(40% - 8px);
        margin-right: 8px;
        font-weight: 500;
    }

    dd {
        width: 60%;
    }
}

.dl-block { // Modifier
    dt,
    dd {
        display: block;
        float: none;
        clear: none;
    }
}
