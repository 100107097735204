.form-heading {
    font-size: 22px;
    font-weight: 400;
}

.help-block {
    margin-top: 5px;
    margin-bottom: 0;
    opacity: 0.5;
    color: #777;
    font-style: italic;
    font-size: 14px;

    .has-error & {
        opacity: 1;
    }

    & + & {
        margin-top: 0;
    }
}

.form-fieldset {
    & + & {
        margin-top: 30px;
    }
}

.form-actions {
    margin-top: 30px;
}

.control-label {
    color: #212121;
    font-weight: 400;
    font-size: 16px;

    &.required {
        &:after {
            content: '*';
            margin-left: 2px;
        }
    }
}

.form-horizontal {
    .row {
        margin-left: -20px;
        margin-right: -20px;
    }

    .form-group {
        margin-left: -20px;
        margin-right: -20px;
    }

    [class*='col-'] {
        padding: 0 20px;
    }
}

.form-help-message {
    display: none;
    font-size: 13px;
}

.form-group {
    margin-bottom: 15px;

    .file-upload {
        margin: 0;
    }

    &.has-error {
        .form-control,
        .select {
            border-color: $error-color;
        }

        .form-help-message {
            display: block;
        }

        select,
        label,
        .form-help-message {
            color: $error-color;
        }
    }
}

.form-description {
    margin-bottom: 40px;
}

.form-toggleable-block-status-item {
    display: block;

    & + & {
        margin-top: 5px;
    }

    strong {
        display: block;
    }
}

.form-notifications {
    .notification {
        margin: 0;
    }
}

@include mq($from: tablet) {
    .form-heading {
        font-size: 24px;
    }

    .form-fieldset {
        & + & {
            margin-top: 40px;
        }
    }

    .form-actions {
        margin-top: 40px;
    }

    .form-group {
        margin-bottom: 25px;
    }

    .form-horizontal {
        .form-group {
            display: flex;
        }

        .control-label {
            width: 20%;
            padding-top: 7px;
            text-align: left;
        }

        .form-heading,
        .form-action,
        .form-description {
            margin-left: 20%;
        }
    }
}
