.measure-list {
    display: block;
}

.measure-list-toggleable {
    .toggleable-row-disabled {
        opacity: 0.5;
    }
}

.measure-list-item {
    display: block;
    padding: 20px 0;
    border-bottom: 1px solid $line-color;
}

.measure-group-usps {
    float: right;
}

.measure-group-content {
    max-width: 720px;
}

.measure-group-title {
    margin-bottom: 0.2em;
    font-size: 24px;
    font-weight: 500;
}

.measure-group-measures {
    margin: 30px 0;
}

.measure-variants {
    padding: 30px 0;
}

.measure-variants-form {
    .control-label {
        margin: 0 0 10px;
    }

    .form-control {
        max-width: 480px;
        padding: 12px;
        border: 1px solid $line-color;
        border-radius: 3px;
    }
}
