.filter-form {
    margin-bottom: 40px;
}

.filter-toggle {
    cursor: pointer;

    &.is-toggled {
        + .filter-content {
            display: block;
        }

        .filter-toggle-icon {
            transform: rotate(180deg);
        }
    }
}

.filter-toggle-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    background: #f4f5f6;
    font-weight: 700;
}

.filter-toggle-icon {
    width: 25px;
    height: 25px;
    fill: $brand-primary;
}

.filter-content {
    display: none;

    .checkbox {
        margin: 0;
        padding: 0 20px;
        border-bottom: 1px solid #f1f1f1;
    }

    label {
        display: block;
        padding-top: 12px;
        padding-bottom: 12px;

        &:before {
            top: 50%;
            margin-top: -10px;
        }
    }

    .filter-is-loading & {
        opacity: 0.5;
        pointer-events: none;
    }
}

.filter-menu-toggle {
    display: none;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;

    svg {
        position: relative;
        top: -2px;
        vertical-align: middle;
    }
}

.filter-backdrop {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background-color: rgba(#000, 0.25);
    pointer-events: none;
    cursor: pointer;
    transition: 0.2s opacity;
}

.filter-heading {
    margin: 1rem 0;
    font-size: 14px;
}

@include mq($until: desktop) {
    .filter {
        position: fixed;
        z-index: 2;
        top: 0;
        right: 100%;
        height: 100vh;
        overflow: scroll;
        background-color: #fff;
        transition: 0.2s transform;

        &.is-open {
            transform: translateX(100%);

            + .filter-backdrop {
                opacity: 1;
                pointer-events: visible;
            }
        }
    }

    .filter-menu-toggle {
        display: inline-block;
    }

    .filter-content {
        label {
            white-space: nowrap;
        }
    }

    .filter-heading {
        display: none;
    }
}
