.spotlight {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
}

.spotlight-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.spotlight-content {
    position: relative;
    z-index: 2;
    max-width: 550px;
    padding: 0 20px;
    text-align: center;

    .icon {
        top: -1px;
        width: 15px;
        height: 15px;
        margin-right: 5px;

        path {
            fill: #fff;
        }
    }

    .btn-link {
        margin-top: 10px;
        color: #fff;
    }
}

.spotlight-heading {
    color: #fff;
    font-size: 32px;

    @include mq($from: desktop) {
        font-size: 44px;
    }
}
