.blocks {
    img {
        max-width: 100%;
        margin: 0.5em 0 1em;
        background-color: mix(#fff, $background-color, 50%);
    }

    table {
        margin: 15px 0 30px;

        th,
        td {
            padding: 8px;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }

        th {
            vertical-align: bottom;
            border-bottom: 2px solid $border-color;
        }

        td {
            vertical-align: top;
            border-bottom: 1px solid $border-color;
        }
    }
}

.block {
    margin-bottom: 2.5vw;
}
