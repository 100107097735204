.revision-item {
    & + & {
        margin-top: 40px;
    }
}

.revision-item-header {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #f5f6f4;
}

.revision-item-heading {
    margin: 0;
    font-size: 20px;
}

.revision-item-description {
    margin: 0;
    opacity: 0.5;
    font-size: 14px;
}
