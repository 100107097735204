.community-comment-form-holder {
    margin: 2rem 0;
    padding: 2rem 0;
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
}

.community-comment-form {
    margin: 0;

    .btn {
        margin: 0 0 0 74px;
    }

    .form-group {
        display: flex;
        margin-bottom: 1rem;
    }

    .redactor-box {
        flex: 1 0;
    }

    .initials-holder {
        align-items: flex-start;
        margin-right: 1.5rem;
    }
}

.community-message-holder {
    width: 100%;
}
