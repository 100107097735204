.measure-variant-blocks {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 12px 16px;
  margin-bottom: 15px;
  padding-left: 0;
}

.measure-variant-block-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.measure-variant-block {
  display: flex;
  flex-direction: column;
  width: 240px;
  color: $text-color;
  padding: 15px;
  border: 1px solid mix(#fff, $line-color, 50%);
  border-radius: 4px;

  &.is-discontinued {
    color: $text-muted;

    .measure-tgn-tag {
      background-color: rgba($brand-primary, 0.5);

      &.measure-tgn-tag-warning {
        background-color: rgba($error-color, 0.5);
      }
    }
  }

  hr {
    margin-top: 12px;
    margin-bottom: 12px;
  }
}

.measure-variant-block-title {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
  color: inherit;
}

.measure-variant-block-button-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.discontinued-title {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: $warning-color;

  svg {
    fill: currentColor;
    width: 16px;
    height: 16px;
  }
}
