// Grid
$grid-gutter-width:                 40px !default;

// Spaces
$space:                             40px !default;

// Breakpoints - matched to bootstrap settings
$screen-xs:                         480px !default;
$screen-sm:                         768px !default;
$screen-md:                         992px !default;
$screen-lg:                         1460px !default;
$screen-xlg:                        1600px !default;

$mq-breakpoints: (
    mobile:  $screen-xs,
    tablet:  $screen-sm,
    desktop: $screen-md,
    wide:    $screen-lg,
    large:   $screen-xlg
);

$content-breakpoint:                1920px !default;

$container-md: $screen-md;
$container-lg: $screen-lg;

$spacing: (
    xs: (0.5 * $space),
    sm: (0.75 * $space),
    base: $space,
    md: (1.25 * $space),
    lg: (2.5 * $space),
    xl: (4 * $space)
);

$hamburger-padding-x:           0;
$hamburger-padding-y:           0;
$hamburger-layer-height:        2px;
$hamburger-layer-spacing:       4px;
$hamburger-layer-width:         22px;
$hamburger-layer-border-radius: 0;
$hamburger-layer-color:         $ro-donkergroen;
$hamburger-hover-opacity:       1;
$hamburger-types: (
    spin,
    spin-r
);

$brand-secondary: #f5f5f6;
$brand-secondary-light: mix(#fff, $brand-secondary, 20%);

$headings-font-weight: 500 !default;

$public-path: '../../../../../web/assets/dashboard/';

$body-color: #333;
