.link {
    display: inline-block;
    font-size: $font-size-base;
    cursor: pointer;
}

.link-primary {
    color: $brand-primary;

    &:hover {
        text-decoration: underline;
    }
}
