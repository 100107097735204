.label {
    display: inline-block;
    padding: 8px 12px;
    margin-top: 2px;
    border-radius: 5px;
    background-color: $brand-primary;
    color: #fff;
    font-weight: 300;
    font-size: 16px;
    line-height: 1;
    text-decoration: none;
}

.label-secondary-outline {
    border: 1px solid $brand-secondary;
    background-color: transparent;
    color: $brand-secondary;
}

.label-default {
    background-color: #ccc;
    color: $text-color;
    font-weight: 500;
}

.label-status {
    background: #3ba6ed;
}
