.users {
    padding: 2rem 0 0 0;
}

.users-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 2rem;
    border-bottom: 1px solid $line-color;
}

.users-image {
    margin: 10px 15px;
}

.users-description {
    text-align: center;
    color: #999;
    font-size: 14px;
}
