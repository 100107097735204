.search {
    display: block;
    width: 100%;
    max-width: 640px;
    margin: 0 auto 40px;
}

.search-input {
    position: relative;

    .form-group {
        margin: 0;
    }

    input {
        padding: 20px 25px 20px 60px;
        border: 1px solid $line-color;

        &:focus {
            border-color: $brand-primary;
        }
    }
}

.search-input-icon {
    position: absolute;
    top: 50%;
    left: 18px;
    display: block;
    width: 32px;
    height: 32px;
    margin-top: -15px;
    pointer-events: none;
}
