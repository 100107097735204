.js-truncate {
    --offset-bottom: 30px;
    --max-height: calc(100px + var(--offset-bottom)); // match threshold in truncate.js

    max-height: var(--max-height);
}

.is-truncated {
    position: relative;
    padding-bottom: var(--offset-bottom);
    overflow: hidden;
    max-height: unset;

    &.is-closed {
        max-height: var(--max-height);
        padding-bottom: 0;

        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            left: 0;
            right: 0;
            bottom: 0;
            height: calc(60px + var(--offset-bottom));
            background: linear-gradient(to top, rgba(#fff, 1) 40%, rgba(#fff, 0) 100%);
        }
    }

    .truncate-toggle {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
    }
}
