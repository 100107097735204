.list {
    display: block;
    margin: 0;
    padding: 0;

    li {
        display: block;
    }
}

.list-separated { // Modifier
    margin: 20px 0;

    li {
        padding: 15px 0;
        border-bottom: 1px solid $line-color;

        &:first-child {
            border-top: 1px solid $line-color;
        }

        > a {
            display: block;
            margin-top: -15px;
            margin-bottom: -15px;
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }
}

.list-icons { // Modifier
    a {
        position: relative;
        padding-left: 32px;
        color: $text-color;

        &:hover,
        &:focus {
            color: $brand-primary;
            text-decoration: none;
        }
    }

    svg {
        position: absolute;
        top: 20px;
        left: 0;
        display: block;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        padding: 0;
        margin: 0 5px 0 0;
        fill: $brand-primary;
    }
}

.list-checkbox {
    display: block;

    .completed-checkbox {
        width: 17px;
        height: 17px;
        margin-right: 10px;
        border-radius: 4px;
        background: #ccc;
        fill: #fff;
    }

    li {
        display: flex;
        align-items: center;
    }
}
