@use "sass:math";

.article-item {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 2rem 0;
    border-top: 1px solid $line-color;
}

.article-item-content {
    flex: 1 0 auto;
}

.article-item-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;

    .article-item-holder:first-of-type {
        .article-item {
            border-top-color: transparent;
        }
    }
}

.article-item-holder {
    width: 100%;
    padding: 0 1.25rem;

    @include mq($from: desktop) {
        width: percentage(math.div(1, 3));
    }
}

.article-item-holder-column { // Modifier
    @include mq($from: desktop) {
        width: 100%;
    }
}

.article-item-heading {
    font-weight: 700;
}

.article-item-datetime {
    display: block;
    margin-bottom: 0.5rem;
    color: #999;
}
