.result__toggle {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    border: 0;
    border-bottom: 1px solid #f6f7f5;
    background-color: transparent;
    font-size: 24px;
    text-align: left;

    &.is-toggled {
        svg {
            transform: rotate(180deg);
        }
    }
}

.result__toggle-icon-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: #f6f7f5;

    svg {
        width: 35px;
        height: 35px;

        path {
            fill: $brand-primary;
        }
    }
}

.result__target {
    display: none;

    &.is-visible {
        display: block;
    }
}

.result__table {
    .table {
        tr:first-child {
            td {
                border-top: 0 !important;
            }
        }

        th {
            border: 0 !important;
        }
    }
}

.result-submit {
    &:hover {
        svg {
            fill: #fff;
        }
    }
}

.result__table-list {
    display: flex;
}

.result__table-name {
    margin-right: 10px;
}
