@use "sass:math";

.main {
    max-width: $content-breakpoint;
    margin: 0 auto;

    .wrapper-building-session & {
        overflow: visible;
    }

    .wrapper-fill & {
        max-width: none;
    }
}

.main-homepage {
    margin-bottom: 5rem;
}

.main-flatten { // Modifier
    flex-grow: 0;
}

.main-content {
    flex: 0 1 auto;
    width: 100%;
    margin: 0 auto;
    padding: 20px;

    &:last-child {
        flex-grow: 1;
    }

    @include mq($from: mobile) {
        padding: 5rem 25px;

        & + & {
            padding-top: 0;
            margin-top: -2.5rem;

            &.main-content-background {
                margin-top: 0;
            }
        }
    }

    @include mq($from: $content-breakpoint) {
        max-width: $content-breakpoint - 480px;
    }
}

.main-content-wide { // Modifier
    max-width: none;

    @include mq($from: mobile) {
        padding-top: 2.5vw;
        padding-bottom: 2.5vw;
    }
}

.main-content-large { // Modifier
    max-width: 1500px;
}

.main-content-small { // Modifier
    max-width: math.div($content-breakpoint, 2);
}

.main-content-form { // Modifier
    max-width: 720px;
}

.main-content-form-wide {
    max-width: 800px;
}

.main-content-background { // Modifier
    position: relative;

    &:before {
        content: '';
        position: absolute;
        left: -100%;
        top: 0;
        display: block;
        width: 300%;
        height: 100%;
        background: $background-color-lighter;
    }

    > * {
        position: relative;
    }
}

.main-content-align { // Modifier
    padding-top: 2.5vw;
    padding-bottom: 2.5vw;

    @include mq($from: $content-breakpoint) {
        max-width: $content-breakpoint - 250px;
    }
}

.main-content-footer {
    margin-top: 60px;
    text-align: center;
}

.main-bar {
    display: flex;
    border-bottom: 1px solid $line-color;
}

.main-bar-image {
    width: 220px;
    background-color: $background-color-lightest;
}

.main-bar-content {
    width: 100%;
    padding: 20px;

    @include mq($from: $content-breakpoint) {
        padding: 40px 25px;
    }
}

.main-bar-title {
    margin: 0;
}

.main-bar-details {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
    padding: 0;

    li {
        display: block;
        flex: 0 0 auto;
        width: 30%;
        padding: 5px 16px;

        > strong {
            display: block;
        }
    }
}

.main-bar-toggle {
    margin-top: 5px;
    text-decoration: none;
    cursor: pointer;
    font-size: $font-size-base;

    &:hover,
    &:focus {
        color: $brand-primary;
        text-decoration: none;
    }
}

// Tabs
.main-content-tabs { // Modifier
    position: relative;

    @for $i from 1 through 10 {
        > input[type='radio'][name^='state-'] ~ .main-content-tab {
            display: none;
        }

        // stylelint-disable selector-max-compound-selectors
        > input[type='radio'][name^='state-']:nth-of-type(#{$i}):checked {
            ~ .main-content-tab:nth-of-type(#{$i}) {
                display: block;
            }

            ~ .main-content-tabbar li:nth-of-type(#{$i}) {
                border-bottom-color: $brand-primary;

                label {
                    color: $brand-primary;
                }
            }
        }
    }
}

.main-content-tabbar {
    position: relative;
    margin-top: -5vw;
    margin-bottom: 5vw;
    background-color: #fff;

    .main-content-background & {
        &:before {
            content: '';
            position: absolute;
            left: -100%;
            top: 0;
            display: block;
            width: 300%;
            height: 100%;
            background: #fff;
        }

        > * {
            position: relative;
        }
    }

    ul {
        display: flex;
        margin: 0 -25px;
        padding: 0;
    }

    li {
        display: block;
        margin: 0 25px;
        border-bottom: 3px solid transparent;
    }

    label {
        display: block;
        padding: 20px 0 25px;
        color: $text-color;
        font-size: $font-size-medium;
        cursor: pointer;

        &:hover,
        &:focus {
            color: $brand-primary;
        }
    }
}

.main-content-description {
    padding-bottom: 2.5vw;
}

.main-content-actions {
    display: flex;
    justify-content: flex-end;
}

.main-content-header {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 16px;

    .btn {
        margin: 0;
    }
}

@include mq($from: wide) {
    .main-content-breakout {
        margin: 0 -200px;
    }
}
