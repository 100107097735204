$header-logo-size: 110px !default;

.header {
    @extend .clearfix;
    position: relative;
    flex: 0 0 auto;
}

.header-bottom-bar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    margin: 0 auto;
    background-color: $brand-primary;
}

.header-bottom-bar-no-breadcrumbs {
    padding-top: 8px;
    padding-bottom: 8px;
}

.header-hamburger {
    width: 100%;
    padding: 5px 1rem 5px 0;
    text-align: right;

    @include mq($from: desktop) {
        display: none;
    }
}

.header-nav {
    display: none;
    padding-bottom: 10px;
    font-size: 14px;

    header.is-open & {
        display: block;
    }

    > ul {
        margin: 0;
        padding: 0;
    }

    li {
        position: relative;
        display: block;
        margin: 0 6px;
    }

    a,
    label {
        display: inline-flex;
        align-items: center;
        margin-bottom: 0;
        padding: 5px 0;
        color: $ro-donkergroen;
        font-weight: 300;
        cursor: pointer;

        &:hover,
        &:focus {
            text-decoration: none;
            color: $brand-primary;
        }
    }

    .icon {
        display: inline-block;
        margin-left: 5px;

        path {
            fill: #fff;
        }
    }

    .initials-holder {
        display: none;
        padding-left: 1rem;
        margin-right: 5px;
    }

    @include mq($from: desktop) {
        display: flex;
        padding-bottom: 0;

        a,
        label {
            padding: 10px 0;
            font-size: 14px;
        }

        > ul {
            display: flex;
            align-items: center;
            padding-right: 10px;
        }

        li {
            display: inline-block;
        }

        .initials-holder {
            display: flex;
        }
    }

    @media (min-height: 1300px) and (min-width: $screen-xlg) {
        a,
        label {
            padding: 15px 0;
            font-size: 14px;
        }
    }
}

.header-nav-user {
    width: 100%;
    padding: 10px 1rem;
    border-top: 1px solid rgba(#fff, 0.25);

    label {
        color: $ro-donkergroen;

        &:hover {
            text-decoration: underline;
        }
    }

    @include mq($from: desktop) {
        position: relative;
        width: auto;
        margin: 0;
        padding: 0;
        border: 0;

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            right: 100%;
            width: 1px;
            height: 24px;
            transform: translateY(-50%);
            opacity: 0.25;
            background-color: $ro-donkergroen;
        }
    }
}

.header-nav-help {
    svg,
    img {
        width: 25px;
        height: 25px;
    }
}

.header-nav-main {
    width: 100%;
    padding-top: 10px;
    padding-left: 1rem;
    padding-right: 1rem;
    border-top: 1px solid rgba(#fff, 0.25);

    @include mq($from: desktop) {
        flex: 1 0;
        justify-content: space-between;
        width: auto;
        margin: 0;
        padding: 0;
        border: 0;
        overflow: hidden;

        a,
        label {
            padding: 1.5rem 0;
            font-size: 18px;
            color: $ro-donkergroen;
        }

        li {
            margin: 0 1rem;

            &.current a {
                font-weight: 700;

                &:before {
                    transform: translateY(-3px);
                }
            }
        }

        a {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                height: 3px;
                background-color: $ro-donkergroen;
                transition: 0.25s transform;
            }

            &:hover,
            &:focus {
                &:before {
                    transform: translateY(-3px);
                }
            }
        }

        ul.is-secondary {
            li.current a {
                text-decoration: underline;
            }

            a {
                font-size: 14px;

                &:hover {
                    text-decoration: underline;
                }

                &:before {
                    content: none;
                }
            }
        }
    }
}

.header-nav-dropdown {
    z-index: 1;
    margin: 0;
    padding: 0;
    border-radius: 2px;
    overflow: hidden;

    a,
    label {
        display: block;
        padding: 5px 15px;
        border-bottom: 1px solid $line-color;
        color: $ro-donkergroen;

        &:last-child {
            border-bottom: none;
        }
    }

    @include mq($from: desktop) {
        position: absolute;
        right: 0;
        display: none;
        min-width: 160px;
        border: 1px solid mix(#fff, $line-color, 25%);
        background-color: #fff;

        &.is-open {
            display: block;
        }

        a,
        label {
            padding: 12px 15px;
            color: mix(#fff, $text-color, 25%);
        }
    }

    li {
        display: block;
        margin: 0;
    }
}

.header-nav-dropdown-trigger {
    display: flex;
    align-items: center;
    padding: 0;
    border: 0;
    background: transparent;
    color: $ro-donkergroen;
}

// Bar
.header-bar {
    position: relative;
    display: flex;
    clear: both;
    margin: 0 auto;
    background-color: $brand-secondary;

    &.is-stuck {
        z-index: 2;
        left: 50%;
        transform: translateX(-50%);
    }

    @include mq($from: $content-breakpoint) {
        .wrapper-fill &:before {
            content: '';
            position: absolute;
            z-index: -1;
            top: -1px;
            bottom: -1px;
            left: -100vw;
            display: block;
            width: 300vw;
            border: 1px solid $line-color;
            border-left: none;
            border-right: none;
        }
    }

    @include mq($until: desktop) {
        flex-direction: column;
    }
}

.header-bar-logo {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    padding: 0.5rem 0;

    a {
        position: relative;
        display: flex;
        padding: 0 1.5rem;

        &:after {
            content: '';
            position: absolute;
            left: 100%;
            width: 1px;
            height: 24px;
            background-color: $ro-donkergroen;
        }
    }

    svg {
        width: 50px;
    }

    @include mq($from: desktop) {
        padding: 0;
    }
}

.header-bar-content {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;

    @include mq($until: desktop) {
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
    }
}

.header-bar-actions {
    display: block;
    margin: 8px 0;
    padding: 0;
    white-space: nowrap;

    li {
        display: inline-block;
        margin: 0 5px;

        &:last-child {
            margin-right: 0;
        }
    }

    a,
    label {
        margin: 0;
    }

    a,
    .btn {
        padding: 3px 15px;
        font-size: 12px;
    }

    .btn {
        &:not(.button-outline) {
            border: 2px solid $brand-primary;
        }

        svg {
            width: 15px;
            height: 15px;
            margin-right: 0.33em;
        }
    }
}

.header-notifications {
    .notification {
        margin-bottom: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
