// Colors
$ro-donkergroen: #275937;
$ro-groen: #39870c;
$ro-lichtblauw: #8fcae7;
$ro-tekstlinks: #154273;

$text-color:                        #212121 !default;
$brand-primary:                     $ro-groen !default;
$brand-primary-lightest:             mix(#fff, $brand-primary, 66%) !default;
$brand-secondary:                   #f1f3f0 !default;
$background-color:                  mix(#fff, $brand-secondary, 25%) !default;
$background-color-lighter:          mix(#fff, $brand-secondary, 33%) !default;
$background-color-lightest:         mix(#fff, $brand-secondary, 66%) !default;
$link-color:                        $brand-primary !default;
$line-color:                        #b8b8b8 !default;
$border-color:                      #e4e4e4 !default;
$backdrop-color:                    #dbdcdb;
$error-color:                       #c52020 !default;
$warning-color:                     #ef8750 !default;
$success-color:                     #6dd75c !default;
$info-color:                        #03a9f4 !default;

// Paths
$base-path: '../../../';
$font-path: $base-path + 'fonts/';

// Typography
$font-size-small:                   12px !default;
$font-size-base:                    16px !default;
$font-size-medium:                  20px !default;
$font-size-large:                   24px !default;
$font-size-largest:                 32px !default;
$line-height-base:                  1.75 !default;
$base-font-family:                  'ROsans', system-ui, sans-serif;
$font-family-sans-serif:            $base-font-family !default;
$font-family-brand:                 $font-family-sans-serif !default;

$font-size-h1:                      $font-size-largest !default;
$font-size-h2:                      $font-size-large !default;
$font-size-h3:                      $font-size-medium !default;

$headings-font-family:              $font-family-brand !default;
$headings-font-weight:              700 !default;
$headings-line-height:              1.45 !default;

// UI
$ui-border-radius:                  5px !default;
$ui-background-color:               #fff !default;
$ui-whitespace:                     30px !default;
