.empty {
    display: block;
    padding: 5vw 20px;
    margin: 0;
    font-size: 20px;
    font-weight: 300;
    color: mix(#fff, $text-color, 50%);

    @include mq($from: mobile) {
        text-align: center;
    }

    p {
        margin-bottom: 1.25em;
    }

    a:not(.btn) {
        color: mix(#fff, $text-color, 40%);
        font-weight: 500;

        &:hover,
        &:focus {
            color: $brand-primary;
            text-decoration: none;
        }
    }
}
