.detailed-notification {
    display: block;
}

.detailed-notification-state {
    display: none;
}

.detailed-notification-notification {
    margin: 0;
    font-size: $font-size-base;
    cursor: pointer;
}

.detailed-notification-content {
    display: none;
    padding: 14px 50px 14px 18px;
    background-color: $background-color-lighter;

    .detailed-notification-state:checked ~ & {
        display: block;
    }
}
