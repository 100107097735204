// Excerpts
.building-excerpts {
    display: flex;
    flex-wrap: wrap;
    margin: 2rem 0 0;
}

.building-excerpt__tab-holder {
    .building-excerpts {
        display: none;
        margin: 2rem 0 0;

        &.tab-content--active {
            display: flex;
        }
    }
}

// Help
.building-excerpt-help {
    margin-bottom: 2rem;
    font-size: 20px;
    color: #777;

    + .search {
        margin-top: 40px;
    }
}

.building-excerpt-help-large {
    text-align: center;
    font-size: 24px;
}
